import { useContext, useState } from "react";
import AuthService from "../../Auth";
import { useRef } from "react";
import { useEffect } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import UserContext from "../User";

const Dados = (props) => {
  const auth = useContext(AuthService);
  const user = useContext(UserContext);
  const nameRef = useRef();
  const documentRef = useRef();
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    formatDocumentNumber();
  }, [auth.user]);

  function onSubmit(e) {
    e.preventDefault();

    resetError();
    resetSuccess();
    setUpdating(true);

    const body = {
      name: nameRef.current.value,
      document: documentRef.current.getAttribute("data-real-value"),
    };

    user.update.personal(body, onSuccess, onError);
  }

  function onSuccess(response) {
    setUpdating(false);
    setSuccess(true);
  }

  function onError() {
    setUpdating(false);
    setError(true);
  }

  function resetError() {
    setError(false);
  }

  function resetSuccess() {
    setSuccess(false);
  }

  function formatDocumentNumber() {
    const value = documentRef.current.value;
    const realValue = value
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/-/g, "");
    let newValue = value;

    if (realValue.length > 2 && realValue.length < 12) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i === 3 || i === 6) {
          newValue += ".";
        }
        if (i === 9) {
          newValue += "-";
        }
        newValue += realValue.charAt(i);
      }
    }

    if (realValue.length > 11) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i > 0 && (i === 2 || i === 5)) {
          newValue += ".";
        }
        if (i > 0 && i === 8) {
          newValue += "/";
        }
        if (i > 0 && i === 12) {
          newValue += "-";
        }

        newValue += realValue.charAt(i);
      }
    }

    documentRef.current.setAttribute("data-real-value", realValue);
    documentRef.current.value = newValue;
  }

  return (
    <form onSubmit={onSubmit}>
      <h3 className="d-none d-lg-block">Meus dados</h3>
      <small className="opacity-6">Todos os campos são obrigatórios</small>

      <div className="form-group mt-lg-5 mt-4">
        <label>Nome completo</label>
        <input
          className="form-control"
          required
          defaultValue={auth.user.name}
          ref={nameRef}
        />
      </div>

      <div className="form-group">
        <label>CPF/CNPJ</label>
        <input
          className="form-control"
          required
          inputMode="numeric"
          data-real-value=""
          defaultValue={auth.user.document}
          ref={documentRef}
          onChange={formatDocumentNumber}
        />
      </div>

      {error && (
        <div className="alert alert-danger fsc-n8 mb-4">
          <div className="row">
            <div className="col">
              Desculpe, mas ocorreu um erro. Tente novamente mais tarde.
            </div>
            <div
              className="col-auto fsc-1p2 cursor-pointer"
              onClick={resetError}
            >
              <FontAwesome icon="times" />
            </div>
          </div>
        </div>
      )}

      {success && (
        <div className="alert alert-success fsc-n8 mb-4">
          <div className="row">
            <div className="col">Informações atualizadas com sucesso!</div>
            <div
              className="col-auto fsc-1p2 cursor-pointer"
              onClick={resetSuccess}
            >
              <FontAwesome icon="times" />
            </div>
          </div>
        </div>
      )}

      <div className="text-end mt-5">
        {updating && (
          <div className="text-success">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}
        {!updating && (
          <button type="submit" className="btn btn-success fw-bold py-2">
            Atualizar
          </button>
        )}
      </div>
    </form>
  );
};

export default Dados;
