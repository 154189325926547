import { useContext } from "react";
import { useState } from "react";
import { useRef } from "react";
import AuthService from "../../Auth";
import { useEffect } from "react";

const GetEmail = ({ onSuccess, tempUser }) => {
  const auth = useContext(AuthService);
  const emailRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    emailRef.current.focus();
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const email = emailRef.current.value;

    auth.checkIfUserExists(email, userExists, userNotExist, onError);
  }

  function userExists() {
    const email = emailRef.current.value;
    setLoading(false);
    onSuccess(1, email);
  }

  function userNotExist() {
    const email = emailRef.current.value;
    setLoading(false);
    onSuccess(2, email);
  }

  function onError(response){
    setLoading(false);
  }

  return (
    <>
      <form onSubmit={onSubmit} className="p-lg-5 p-4">
        <h3 className="text-center">Entrar</h3>
        <div className="text-center my-3">
          <div>Seja bem-vindo!</div>
          <div>Informe por favor seu e-mail.</div>
        </div>

        <input
          required
          id='email-register'
          type="email"
          name="email"
          className="form-control py-3 my-5 text-center"
          placeholder="E-mail"
          ref={emailRef}
          defaultValue={tempUser.email}
        />
        {loading && (
          <div className="btn btn-success w-100 mt-2 py-3 fw-bold">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}
        {!loading && (
          <button className="btn btn-success w-100 mt-2 py-3 fw-bold">
            Continuar
          </button>
        )}
      </form>
    </>
  );
};

export default GetEmail;
