import { useContext } from "react";
import { useRef } from "react";
import AuthService from "../Auth/Auth";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";
import { useState } from "react";
import UserContext from "../Auth/User/User";

const DadosContato = (props) => {
  const user = useContext(UserContext);
  const auth = useContext(AuthService);
  const telRef = useRef();
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    formatTel();
  }, [auth.user]);

  function onSubmit(e) {
    e.preventDefault();

    resetError();
    resetSuccess();
    setUpdating(true);

    const body = {
      tel: telRef.current.getAttribute("data-real-value"),
    };

    user.update.contact(body, onSuccess, onError);
  }

  function onSuccess(response) {
    setUpdating(false);
    setSuccess(true);
  }

  function onError() {
    setUpdating(false);
    setError(true);
  }

  function resetError() {
    setError(false);
  }

  function resetSuccess() {
    setSuccess(false);
  }

  function formatTel() {
    const value = telRef.current.value;
    const realValue = value
      .replace(/ /g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/-/g, "");
    let newValue = "";

    for (let i = 0; i < realValue.length; i++) {
      if (i === 0) {
        newValue += "(";
      }
      if (i === 2) {
        newValue += ") ";
      }
      if (i === 7) {
        newValue += "-";
      }
      newValue += realValue.charAt(i);
    }

    telRef.current.setAttribute("data-real-value", realValue);
    telRef.current.value = newValue;
  }

  return (
    <>
      <div className="container px-4" style={{ maxWidth: 500 }}>
        <h2 className="my-5">Dados de contato</h2>

        <form onSubmit={onSubmit}>
          <div className="form-group mt-lg-5 mt-4">
            <label>Celular</label>
            <input
              className="form-control"
              required
              placeholder="(xx) xxxxx-xxxx"
              type="tel"
              autoComplete="tel"
              ref={telRef}
              data-real-value=""
              onChange={formatTel}
              defaultValue={auth.user.tel}
            />
          </div>

          <div className="form-group">
            <label>E-mail</label>
            <input
              className="form-control"
              required
              type="email"
              readOnly
              disabled
              defaultValue={auth.user.email}
            />
          </div>

          {error && (
            <div className="alert alert-danger fsc-n8 mb-4">
              <div className="row">
                <div className="col">
                  Desculpe, mas ocorreu um erro. Tente novamente mais tarde.
                </div>
                <div
                  className="col-auto fsc-1p2 cursor-pointer"
                  onClick={resetError}
                >
                  <FontAwesome icon="times" />
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="alert alert-success fsc-n8 mb-4">
              <div className="row">
                <div className="col">Informações atualizadas com sucesso!</div>
                <div
                  className="col-auto fsc-1p2 cursor-pointer"
                  onClick={resetSuccess}
                >
                  <FontAwesome icon="times" />
                </div>
              </div>
            </div>
          )}

          <div className="row align-self-center justify-content-between">
            <div className="col-auto">
              <NavLink to={"/meus-dados"}>
                <div className="btn btn-outline-secondary fw-bold py-2 link">
                  Voltar
                </div>
              </NavLink>
            </div>
            <div className="col-auto">
              {updating && (
                <div className="text-success">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </div>
              )}
              {!updating && (
                <button type="submit" className="btn btn-success fw-bold py-2">
                  Atualizar
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DadosContato;
