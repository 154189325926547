import { useRef } from "react";
import { useState } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import { useContext } from "react";
import AuthService from "../../Auth";
import { useEffect } from "react";

const LogWithPass = ({ tempUser, onCancel, onSuccess }) => {
  const auth = useContext(AuthService);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const passRef = useRef();
  const [error, setError] = useState(false);

  useEffect(() => {
    passRef.current.focus();
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    setLoading(true);
    resetError(false);

    const email = tempUser.email;
    const password = passRef.current.value;

    auth.signInWithPassword(email, password, onSuccess, onError);
  }

  function togglePass() {
    setShowPass(!showPass);
  }

  function resetError() {
    setError(false);
  }

  function onError(response) {
    setLoading(false);
    setError(response.message ? response.message : true);
  }

  return (
    <>
      <form onSubmit={onSubmit} className="p-lg-5 p-4">
        <h3 className="text-center">Entrar</h3>
        <div className="text-center my-3">
          <div>Que bom tê-lo de volta!</div>
          <div>Informe por favor sua senha.</div>
        </div>

        <div className="row align-items-center mt-5">
          <div className="col">
            <input
              required
              type={showPass ? "text" : "password"}
              name="password"
              className="form-control py-3"
              placeholder="Senha"
              ref={passRef}
            />
          </div>
          <div
            className="col-auto text-secondary cursor-pointer"
            onClick={togglePass}
          >
            {showPass ? (
              <FontAwesome icon="eye-slash" />
            ) : (
              <FontAwesome icon="eye" />
            )}
          </div>
        </div>
        
        <div className="mt-2">
            <a href="/recuperar-senha" target="_blank">Esqueci minha senha</a>
        </div>

        {error && (
          <div className="alert alert-danger fsc-n8 mt-2">
            <div className="row">
              <div className="col">
                {error === true ? (
                  <>
                    Desculpe, mas ocorreu um erro. Tente novamente mais tarde.
                  </>
                ) : (
                  error
                )}
              </div>
              <div
                className="col-auto fsc-1p2 cursor-pointer"
                onClick={resetError}
              >
                <FontAwesome icon="times" />
              </div>
            </div>
          </div>
        )}

        <div className="mt-5">
          {loading && (
            <div className="btn btn-success w-100 mt-2 py-3 fw-bold">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </div>
            </div>
          )}
          {!loading && (
            <button className="btn btn-success w-100 mt-2 py-3 fw-bold">
              Continuar
            </button>
          )}
        </div>

        <div className="text-center">
          <div
            className="d-inline-block text-success mt-3 cursor-pointer"
            onClick={onCancel}
          >
            Voltar
          </div>
        </div>
      </form>
    </>
  );
};

export default LogWithPass;
