import { useContext } from "react";
import AuthService from "../../Auth/Auth";
import FontAwesome from "../../../components/Layout/FontAwesome";
import UserContext from "../../Auth/User/User";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const PersonalInfo = forwardRef((props, ref) => {
  const auth = useContext(AuthService);
  const user = useContext(UserContext);
  const componentRef = useRef();

  useImperativeHandle(ref, ()=>({
    getUser
  }));

  function getUser(onSignin){
    if(!auth.logged){
        auth.onSigin(onSignin);
        user.open(false);
        return false;
    };

    return {...auth.user};
  }

  function openUser() {
    user.open(false);
  }

  function formatedTel() {
    let newValue = "";

    for (let i = 0; i < auth.user.tel.length; i++) {
      if (i === 0) {
        newValue += "(";
      }
      if (i === 2) {
        newValue += ") ";
      }
      if (i === 7) {
        newValue += "-";
      }

      newValue += auth.user.tel.charAt(i);
    }

    return newValue;
  }

  function formatDocumentNumber() {
    const document = auth.user.document;
    let newValue = "";

    if (document.length < 12) {
      newValue = "";

      for (let i = 0; i < document.length; i++) {
        if (i === 3 || i === 6) {
          newValue += ".";
        }
        if (i === 9) {
          newValue += "-";
        }
        newValue += document.charAt(i);
      }
    }

    if (document.length > 11) {
      newValue = "";

      for (let i = 0; i < document.length; i++) {
        if (i > 0 && (i === 2 || i === 5)) {
          newValue += ".";
        }
        if (i > 0 && i === 8) {
          newValue += "/";
        }
        if (i > 0 && i === 12) {
          newValue += "-";
        }

        newValue += document.charAt(i);
      }
    }

    return newValue;
  }

  return (
    <>
      <div className="fsc-m-n8 pt-5" ref={componentRef}>
        <h3 className="mb-3 fw-500">Informações Pessoais</h3>

        <div className="row align-items-center py-2">
          <div className="col-auto text-success fsc-3">
            <FontAwesome icon="user" />
          </div>
          <div className="col fsc-1p2">
            <div className="row">
              <div className="col-lg col-12">
                {auth.logged && (
                  <>
                    <div>{auth.user.name}</div>
                    <div className="opacity-7 fsc-n8">
                      <div className="d-inline-block me-3">
                        <FontAwesome icon="phone" /> {" " + formatedTel()}
                      </div>

                      <div className="d-inline-block me-3">
                        <FontAwesome icon="id-card" />{" "}
                        {" " + formatDocumentNumber()}
                      </div>
                    </div>
                  </>
                )}
                {!auth.logged && (
                  <div className="opacity-7">Insira suas informações</div>
                )}
              </div>
              <div
                className="col-lg-auto col-12 text-end text-success cursor-pointer"
                onClick={openUser}
              >
                Modificar
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PersonalInfo;
