import { useRef, useState } from "react";
import Modal from "../../../components/Layout/Modal/Modal";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import Price from "../../../misc/classes/Filters/Price";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useFirebase from "../../../hooks/useFirebase";
import { useContext } from "react";
import CartList from "../../Cart/CartList";

const PagarPix = forwardRef((props, ref) => {
  const cart = useContext(CartList);
  const { firebaseInit, database, databaseFunc } = useFirebase(["database"]);
  const [response, setResponse] = useState({ order: null });
  const [copy, setCopy] = useState(false);
  const qrCodeRef = useRef();
  const modalRef = useRef();
  const navigate = useNavigate();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function open(response) {
    firebaseInit();
    setResponse(response);
    modalRef.current.open();
  }

  useEffect(() => {
    if (database !== null && response.order !== null) {
      listenOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, database]);

  function listenOrder() {
    databaseFunc.getRef("orders/" + response.order + "/pix", onListenerUpdate);
  }

  function onListenerUpdate(value, off) {
    if (value === true) {
      off();
      goToOrder();
    }
  }

  function copyQrCode() {
    const inputEl = qrCodeRef.current;
    navigator.clipboard.writeText(inputEl.value).finally(textIsCopied);
  }

  function textIsCopied() {
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 500);
  }

  function goToOrder() {
    cart.reset();
    modalRef.current.close();
    navigate("/pedidos/" + response.order);
  }

  return (
    <>
      <Modal
        ref={modalRef}
        id="pagar-pix"
        isStatic={true}
        className="modal-headless modal-lg"
      >
        <div className="p-lg-5 p-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12 text-center">
              <h4>Estamos quase lá!</h4>

              <div className="fw-light my-5">
                <div className="mb-3">
                  Para completar seu pedido, por favor efetue o pagamento por
                  Pix.
                </div>
                <div className="d-none d-lg-block">
                  Escaneie o código ao lado, ou se preferir, copie e cole o
                  código abaixo com seu aplicativo bancário.
                </div>

                <div className="d-lg-none">
                  Copie e cole o código abaixo com seu aplicativo bancário.
                </div>
              </div>

              <input
                ref={qrCodeRef}
                defaultValue={
                  response.order !== null ? response.payment.qr_code.qr_code : ""
                }
                readOnly
                disabled
                className="form-control cursor-pointer"
                onClick={copyQrCode}
              />

              <div
                className="btn btn-success py-3 px-5 mt-4 fw-bold w-100"
                onClick={copyQrCode}
              >
                {copy && "Copiado!"}
                {!copy && "Copiar"}
              </div>
            </div>
            <div className="col-5 d-none d-lg-block px-4">
              {response.order !== null && (
                <img
                  src={`data:image/png;base64,${response.payment.qr_code.qr_code_base64}`}
                  alt=""
                  className="w-100"
                />
              )}

              {response.order !== null && response.payment.total && (
                <div className="text-center fw-light fsc-1p2 mt-4">
                  {Price.numberToCurrency(response.payment.total)}
                </div>
              )}
            </div>
          </div>

          <hr className="my-lg-5 my-4" />

          <div className="alert alert-secondary fsc-n8 mb-0 text-center">
            <strong>Não feche esta janela.</strong>
            <br /> Nosso sistema detectará automaticamente o pagamento e atualizar a página.
            <br />  Caso tenha efetuado o pagamento, mas não tenha sido redirecionado,{" "}
            <div
              className="d-inline-block text-primary cursor-pointer text-decoration-underline"
              onClick={goToOrder}
            >
              clique aqui
            </div>
            .
          </div>
        </div>
      </Modal>
    </>
  );
});

export default PagarPix;
