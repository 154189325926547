class HttpRequest {
  static call(url, method, body = null, success, error) {
    return fetch(process.env.REACT_APP_API_URL + url, {
      method: method,
      body: body,
    })
    .then((response)=> response.json())
    .then((data)=>{
        if(data.status >= 200 && data.status < 300){
            success(data);
        } else {
            error(data);
            if(process.env.NODE_ENV !== 'production') console.log(data);
        };
    })
    .catch((e)=>{
        error(e);
        if(process.env.NODE_ENV !== 'production') console.log(e);
    })
  }

  static post(url, body, success = null, error = null) {
    return this.call(url, "POST", body, success, error);
  }

  static get(url, success = null, error = null) {
    return this.call(url, "GET", null, success, error);
  }
  
  static put(url, body, success = null, error = null) {
    body = this.formdata_to_json(body);

    return this.call(url, "PUT", body, success, error);
  }
  
  static delete(url, success = null, error = null) {
    return this.call(url, "DELETE", null, success, error);
  }

  static formdata_to_json(formData){
    const object = {};

    formData.forEach((value, key)=>{
        object[key] = value;
    });

    return JSON.stringify(object);
  }
}

export default HttpRequest;
