import { useContext } from "react";
import Price from "../../../misc/classes/Filters/Price";
import ItemIngredients from "./ItemIngredients";
import CartList from "../CartList";

const ItemPedido = ({ product, index }) => {
  const cart = useContext(CartList);

  function remove(){
    cart.remove(index);
  }

  function edit(){
    cart.openEdit(product, index);
  }

  return (
    <>
      <div className="border-top py-4">
        <div className="row fsc-1p2 fsc-m-1">
          <div className="col">
            {product.quantity}x {product.name}
          </div>
          <div className="col-auto fw-500">
            {Price.numberToCurrency(product.final_price)}
          </div>
        </div>

        {product.choosed_ingredients.length > 0 && (
          <div className="my-2 fsc-n9 opacity-7">
            <ItemIngredients
              ingredients={product.choosed_ingredients}
              subgroups={product.ingredients}
            />
          </div>
        )}

        <div className="row fw-bold mt-2 fsc-m-n8">
          <div className="col-auto text-success cursor-pointer" onClick={edit}>Editar</div>
          <div className="col-auto text-secondary cursor-pointer" onClick={remove}>Remover</div>
        </div>
      </div>
    </>
  );
};

export default ItemPedido;
