import { useEffect } from "react";
import { useRef } from "react";

const GetContato = ({ tempUser, onSuccess, onCancel }) => {
  const telRef = useRef();

  useEffect(()=>{
    telRef.current.focus();
    formatTel();
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    const tel = telRef.current.getAttribute('data-real-value');

    onSuccess(tel);
  }

  function formatTel() {
    const value = telRef.current.value;
    const realValue = value
      .replace(/ /g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/-/g, "");
    let newValue = "";

    for (let i = 0; i < realValue.length; i++) {
      if (i === 0) {
        newValue += "(";
      }
      if (i === 2) {
        newValue += ") ";
      }
      if (i === 7) {
        newValue += "-";
      }
      newValue += realValue.charAt(i);
    }

    telRef.current.setAttribute("data-real-value", realValue);
    telRef.current.value = newValue;
  }

  return (
    <>
      <form onSubmit={onSubmit} className="p-lg-5 p-4">
        <h3 className="text-center">Dados de contato</h3>
        <div className="text-center my-3">
          <div>Para podermos continuar, por favor insira seu celular.</div>
        </div>

        <div className="form-group mt-5">
          <label>Celular/Whatsapp</label>
          <input
            className="form-control py-2"
            required
            autoComplete="telephone"
            inputMode="number"
            pattern="[0-9\s\-\(\)]{14}|[0-9\s\-\(\)]{15}"
            ref={telRef}
            maxLength={15}
            data-real-value={tempUser.tel}
            onChange={formatTel}
            defaultValue={tempUser.tel}
          />
        </div>

        <button className="btn btn-success w-100 mt-2 py-3 fw-bold">
          Continuar
        </button>

        <div className="text-center">
          <div
            className="d-inline-block text-success mt-3 cursor-pointer"
            onClick={onCancel}
          >
            Voltar
          </div>
        </div>
      </form>
    </>
  );
};

export default GetContato;
