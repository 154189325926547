import { useState } from "react";
import ModalCardBrick from "./MercadoPago/ModalCardBrick";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import LocalPayments from "./LocalPayments";
import NotDefined from "./UI/NotDefined";
import TabsPaymentOption from "./UI/TabsPaymentOption";
import OnlinePayment from "./OnlinePayment";
import { useContext } from "react";
import Pagamentos from "../../../hooks/Configurations/Pagamentos";

const Pagamento = forwardRef((props, ref) => {
    const pagamentoMethodsContext = useContext(Pagamentos);

  const [payment, setPayment] = useState({
    option: pagamentoMethodsContext.pagamentos.online === true ? "DIRECT" : "LOCAL",
    method: null,
    informations: {},
  });

  const [notDefined, setNotDefined] = useState(false);
  const componentRef = useRef();

  useImperativeHandle(ref, () => ({
    getPayment,
    clearInformations,
  }));

  function getPayment() {
    if (!payment.method) {
      setNotDefined(true);
      componentRef.current.scrollIntoView();
      return false;
    }

    if (
      payment.method === "credit" &&
      (!payment.informations.token || !payment.informations.issuer_id)
    ) {
      setNotDefined(true);
      componentRef.current.scrollIntoView();
      return false;
    }

    return { ...payment };
  }

  function clearInformations() {
    let newPayment = { ...payment };
    newPayment.informations = {};
    setPayment(newPayment);
  }

  function setPaymentVariables(option, method = null) {
    setNotDefined(false);
    const newPayment = { ...payment };
    newPayment.option = option;
    newPayment.method = method;

    setPayment(newPayment);
  }

  function selectLocalPay(e) {
    var target = e.target;
    var attribute = "data-value";
    var parentWithAttribute = null;

    while (target && !parentWithAttribute) {
      if (target.hasAttribute(attribute)) {
        parentWithAttribute = target;
      } else {
        target = target.parentNode;
      }
    }

    if (parentWithAttribute) {
      const value = parentWithAttribute.getAttribute("data-value");

      setPaymentVariables("LOCAL", value);
    }
  }

  function cardInformed(informations) {
    const newPayment = { ...payment };
    newPayment.option = "DIRECT";
    newPayment.method = "credit";
    newPayment.informations = informations;

    setPayment(newPayment);
  }

  return (
    <>
      <div className="fsc-m-n8 pt-5" ref={componentRef}>
        <h3 className="mb-3 fw-500">Pagamento</h3>

        {notDefined && (
          <NotDefined
            hide={() => {
              setNotDefined(false);
            }}
          />
        )}

        <TabsPaymentOption
          setTab={(tab) => {
            setPaymentVariables(tab);
          }}
          payment={payment}
        />

        {pagamentoMethodsContext.pagamentos.online && payment.option === "DIRECT" && (
          <OnlinePayment
            payment={payment}
            setPaymentVariables={setPaymentVariables}
          />
        )}

        {payment.option === "LOCAL" && (
          <LocalPayments payment={payment} selectLocalPay={selectLocalPay} />
        )}
      </div>

      <ModalCardBrick enable={false} onSuccess={cardInformed} />
    </>
  );
});

export default Pagamento;
