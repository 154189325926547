import { useState } from "react";
import { createContext } from "react";

const NavService = createContext({
    scrollNav: (index) => {},
    scrollPage: (index) => {},
    setActive: (index) => {},
    active: 0,
    navClick: () => {}
});

export default NavService;

export const NavServiceProvider = (props) => {
    const [active, setActive] = useState(0);

    function _setActive(index){        
        setActive(index);
    }
    
    function scrollNav(index){
        const navButton = document.getElementById("cardapio-nav-" + index);
        const parent = document.getElementById('cardapio-nav');
        const parentWidth = parent.offsetWidth;
        const offset = (parentWidth - navButton.offsetWidth) / 2;
        const position = navButton.offsetLeft - offset;
    
        parent.scrollTo({
            left: position,
            behavior: "auto"
        });
    }

    function scrollPage(index){
        const navElement = document.getElementById("category-" + index);
        const offset = navElement.offsetTop;
    
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    }

    function navClick(){
        const parent = document.getElementById('cardapio-nav');
        parent.setAttribute('data-scrolling-end', Date.now() + 1000);
    }

    const context = {
        scrollNav: scrollNav,
        scrollPage: scrollPage,
        setActive: _setActive,
        active: active,
        navClick: navClick
      };

   return (
       <NavService.Provider value={context}>
            {props.children}
       </NavService.Provider>
   )
}