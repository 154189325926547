import CSS from "./Nav.module.css";
import AuthService from "../../../Paginas/Auth/Auth";
import { useContext } from "react";
import NavItemMobile from "./NavItemMobile";

const NavMobile = ({ signIn }) => {
  const auth = useContext(AuthService);

  return (
    <>
      <nav
        className={
          "row align-items-center mx-0 w-100 border-top d-lg-none d-print-none " +
          CSS.nav_mobile
        }
      >
        <div className="row mx-0 align-items-center">
          <div className="col-2 py-2">
            <img
              alt="logo"
              src="img/logo/logo-full-small.png"
              className="w-100"
            />
          </div>
          <div className="col-10">
            <div className="row">
              <div
                className={
                  "text-center text-secondary p-0 fsc-n9 fw-bold " +
                  (auth.logged ? "col-4" : "col-6")
                }
              >
                <NavItemMobile to="/" exact={true} icon="utensils">
                  Cardápio
                </NavItemMobile>
              </div>

              {auth.logged && (
                <>
                  <div className="col-4 text-center text-secondary fw-bold p-0 fsc-n9">
                    <NavItemMobile to="/pedidos" icon="receipt">
                      Pedidos
                    </NavItemMobile>
                  </div>
                  
                  <div className="col-4 text-center text-secondary fw-bold p-0 fsc-n9">
                    <NavItemMobile to="/meus-dados" icon="user-cog">
                      Meus Dados
                    </NavItemMobile>
                  </div>
                </>
              )}

              {!auth.logged && (
                <div
                  className="col-6 text-center text-secondary fw-bold p-0 fsc-n9"
                  onClick={signIn}
                >
                  <NavItemMobile to={null} icon="user">
                    Acessar
                  </NavItemMobile>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={CSS.nav_border_bottom}>

        </div>
      </nav>
    </>
  );
};

export default NavMobile;
