import { useRef } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import { useState } from "react";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { useContext } from "react";
import CartList from "../../Cart/CartList";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";

const Cupom = forwardRef((props, ref) => {
  const cupomRef = useRef();
  const [error, setError] = useState(null);
  const cart = useContext(CartList);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useImperativeHandle(ref, () => ({ getCupom }));

  function getCupom() {
    return cart.cupom === null ? null : cart.cupom.name;
  }

  useEffect(() => {
    if (cart.cupom !== null) {
      cupomRef.current.value = cart.cupom.name;
      setSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    setError(null);
    setLoading(true);
    const value = cupomRef.current.value;

    HttpRequest.get("/cupons/" + value, onSuccess, onError);
  }

  function onSuccess(response) {
    setLoading(false);
    cart.setCupom(response.cupom);
    setSuccess(true);
  }

  function onError(response) {
    setLoading(false);
    setError(response.error);
  }

  function resetCupom(){
    cart.setCupom(null);
    setSuccess(false);
  }

  return (
    <>
      <div className="fsc-m-n8 pt-5">
        <div className="row align-items-center py-2">
          <div className="col-auto text-success fsc-2 ">
            <FontAwesome icon="ticket" />
          </div>
          <div className="col fsc-1p2">
            <div className="row">
              <div className="col-lg col-12">
                <form className="row" onSubmit={onSubmit}>
                  <div className="col">
                    <input
                      className="form-control"
                      ref={cupomRef}
                      placeholder="Código do Cupom"
                      disabled={success}
                      required
                    />
                  </div>
                  <div className="col-auto">
                    {!loading && (
                      <>
                        {!success && (
                          <>
                            <button className="btn btn-outline-success fw-500">
                              Adicionar
                            </button>
                          </>
                        )}
                        
                        {success && (
                          <>
                            <div className="btn btn-outline-success fw-500" onClick={resetCupom}>
                              Trocar
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {loading && (
                      <>
                        <div className="px-4 text-success">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">
                              Carregando...
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
                {error !== null && (
                  <>
                    <div className="text-danger mt-2 fsc-n7">
                      {error.message}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Cupom;
