import { useContext } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import PriceDisplay from "../PriceDisplay";
import CSS from "./ProdutosCard.module.css";
import ModalProdutosContext from "../../../ModalProdutos/ModalProdutosContext";

const ProdutosCard = ({ product }) => {
    const modalProdutos = useContext(ModalProdutosContext);

    function open(){
        modalProdutos.addNew(product);
    }

  return (
    <div className="col-lg-6 col-12 py-lg-4 align-self-stretch">
      <div className={CSS.card + " rounded px-lg-4 py-4 px-2"} onClick={open}>
        <div className="row mx-0 h-100">
          <div className="col px-0">
            <div className="d-flex flex-column h-100">
              <div style={{ flex: "1" }}>
                <h3 className="h3 fw-500 fsc-m-1">{product.name}</h3>

                {product.description && (
                  <div className="opacity-7 my-lg-4 my-3 fsc-m-n8">{product.description}</div>
                )}

                {product.people !== null && product.people > 0 && (
                  <div className="my-3 fw-500 mb-lg-5 my-4 fsc-m-n8">
                    <FontAwesome icon="users" />{" "}
                    <span className="ms-2">Serve {product.people} pessoas</span>
                  </div>
                )}
              </div>

              <div className="fsc-1p2 fsc-m-1">
                {product.ingredients.length > 0 && <span>A partir de{' '}</span>}
                <PriceDisplay product={product} />
              </div>
            </div>
          </div>
          {product.photo && (
            <div className="col-lg-4 col-5 pe-0 ps-4 text-center">
              <img
                className="w-100 rounded"
                src={process.env.REACT_APP_IMG_URL + '/min/' + product.photo}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProdutosCard;
