const PriceDisplay = ({ product }) => {
  return (
    <>
      {product.discount > 0 && (
        <>
          <span className="text-success">
            R$ {product.price_formated}
          </span>
          <span className="fsc-n8 ms-2 text-secondary text-decoration-line-through">
            R$ {product.price_full_formated}
          </span>
        </>
      )}
      {product.discount === 0 && (
        <>R$ {product.price_formated}</>
      )}
    </>
  );
};

export default PriceDisplay;
