import { NavLink } from "react-router-dom";
import CSS from "./NavItem.module.css";

const ButtonNav = (props) => {
  return (
    <>
      <NavLink
        to={props.to}
        end={props.exact}
        className={({ isActive }) =>
          CSS.nav_item + " " + (isActive ? CSS.active : null)
        }
      >
        <div className={"btn btn-outline-secondary rounded-pill px-4 " + CSS.btn}>
          {props.children}
        </div>
      </NavLink>
    </>
  );
};

export default ButtonNav;
