import { useEffect } from "react";
import { useRef } from "react";

const GetMeusDados = ({ onSuccess, onCancel, tempUser }) => {
  const nameRef = useRef();
  const documentRef = useRef();

  useEffect(() => {
    nameRef.current.focus();
    formatDocumentNumber();
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    const name = nameRef.current.value;
    const document = documentRef.current.getAttribute("data-real-value");

    onSuccess(name, document);
  }

  function formatDocumentNumber() {
    const value = documentRef.current.value;
    const realValue = value
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/-/g, "");
    let newValue = value;

    if (realValue.length > 2 && realValue.length < 12) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i === 3 || i === 6) {
          newValue += ".";
        }
        if (i === 9) {
          newValue += "-";
        }
        newValue += realValue.charAt(i);
      }
    }

    if (realValue.length > 11) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i > 0 && (i === 2 || i === 5)) {
          newValue += ".";
        }
        if (i > 0 && i === 8) {
          newValue += "/";
        }
        if (i > 0 && i === 12) {
          newValue += "-";
        }

        newValue += realValue.charAt(i);
      }
    }

    documentRef.current.setAttribute("data-real-value", realValue);
    documentRef.current.value = newValue;
  }

  return (
    <>
      <form onSubmit={onSubmit} className="p-lg-5 p-4">
        <h3 className="text-center">Meus Dados</h3>
        <div className="text-center my-3">
          <div>Para podermos continuar, por favor insira seus dados.</div>
        </div>

        <div className="form-group mt-5">
          <label>Nome completo</label>
          <input
            className="form-control py-2"
            required
            title="Insira seu nome completo"
            ref={nameRef}
            defaultValue={tempUser.name}
          />
        </div>

        <div className="form-group">
          <label>CPF/CNPJ</label>
          <input
            className="form-control py-2"
            required
            inputMode="numeric"
            pattern="[0-9\.-]{14}|[0-9\.-\/]{18}"
            maxLength={18}
            title='Insira o número do seu documento'
            ref={documentRef}
            defaultValue={tempUser.document}
            data-real-value={tempUser.document}
            onChange={formatDocumentNumber}
          />
        </div>

        <button className="btn btn-success w-100 mt-2 py-3 fw-bold">
          Continuar
        </button>

        <div className="text-center">
          <div
            className="d-inline-block text-success mt-3 cursor-pointer"
            onClick={onCancel}
          >
            Voltar
          </div>
        </div>
      </form>
    </>
  );
};

export default GetMeusDados;
