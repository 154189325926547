import { createContext, useEffect, useState } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";
import Modal from "../../components/Layout/Modal/Modal";
import { useRef } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";

const Horarios = createContext({
  open: () => {},
  warning: () => {},
});

export default Horarios;

export function HorariosProvider(props) {
  const [horarios, setHorarios] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    getHorarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getHorarios() {
    HttpRequest.get("/configuracoes/horarios", onSuccessHorarios);
  }

  function onSuccessHorarios(response) {
    setHorarios(response.horarios);
  }

  function isOpen() {
    if (horarios.open === false) {
      return false;
    }

    const now = new Date();

    const currentTime = now.toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    let currentSeconds = currentTime.split(':');
    currentSeconds = getHoursInSeconds({hour: currentSeconds[0], minute: currentSeconds[1]});

    const openingSeconds = getHoursInSeconds(horarios["today"].start);
    const closingSeconds = getHoursInSeconds(horarios["today"].end);

    return currentSeconds >= openingSeconds && currentSeconds <= closingSeconds;
  }

  function getHoursInSeconds(hoursMinutes) {
    let {hour, minute} = {...hoursMinutes};

    hour = parseInt(hour) * 60 * 60;
    minute = parseInt(minute) * 60;

    return hour + minute;
  }

  function showModal() {
    modalRef.current.open();
  }

  const context = {
    open: isOpen,
    warning: showModal,
  };

  return (
    <Horarios.Provider value={context}>
      <Modal ref={modalRef} id="warning-time">
        <div className="p-4 text-center">
          <div className="fsc-5 text-success">
            <FontAwesome icon="clock" />
          </div>

          <div className="fsc-2 fw-bold">Estamos fechados</div>

          <div className="fw-light my-5">
            <p>
              No momento, estamos fechados, porém você pode visualizar nosso
              cardápio a vontade.
            </p>
            {horarios !== null && <p></p>}
          </div>

          {horarios !== null && horarios.message !== null && horarios.message !== '' && (<>
            <div className="alert alert-secondary">
                {horarios.message}
            </div>          
          </>)}

          {horarios !== null && horarios.message === null && (
            <>
              <div className="fw-light mb-4">
                Nossos horários de atendimento:
              </div>

              <div className="horarios mb-5">
                {horarios.days.map((day, index) => {
                  return (
                    <div
                      className="row align-items-center justify-content-center"
                      key={index}
                    >
                      <div className="col-4 fw-bold text-start">{day.name}</div>
                      <div className="col-4">
                        {day.open === "0" && "Fechado"}
                        {day.open === "1" && (
                          <>
                            {day.start.hour}:{day.start.minute} até{" "}
                            {day.end.hour}:{day.end.minute}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Modal>

      {props.children}
    </Horarios.Provider>
  );
}
