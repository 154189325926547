import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";

const ListService = createContext({
  change: (newList) => {},
  list: [],
  original: [],
  copy: () => {},
  json: () => {},
  touched: false,
  reset: () => {},
  search: () => {},
});
export default ListService;

export function ListServiceProvider(props) {
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]);
  const [touched, setTouched] = useState(false);
  const [secondList, setSecondList] = useState(false);
  const [searchNeedle, setSearchNeedle] = useState(null);

  useEffect(() => {
    change(props.list);

    if (props.skipSecondList) {
      setSecondList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.list, props.skipSecondList]);

  useEffect(() => {
    searchInArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalList, searchNeedle]);

  function change(newList) {
    setOriginalList(newList);

    if (!secondList) {
      setSecondList(true);
    }

    if (secondList && !touched) {
      setTouched(true);
    }
  }

  function copy() {
    return [...list];
  }

  function json() {
    return JSON.stringify(copy());
  }

  function search(needle) {
    setSearchNeedle(needle.toLowerCase());
  }

  function reset(){
    setTouched(false);
  }

  function searchInArray() {
    if (!searchValid()) {
        setTimeout(()=>{
            setList([...originalList]);
        }, 100);
      return;
    }

    let newList = [];

    for (let categoryIndex = 0; categoryIndex < originalList.length; categoryIndex++) {
        const category = {...originalList[categoryIndex]};   

        let newCategoryList = [];

        for (let productIndex = 0; productIndex < category.list.length; productIndex++) {
            const product = category.list[productIndex];

            let element = getOnlyValues(product);
            element = element.toLowerCase();
            
            if (element.indexOf(searchNeedle) > -1) {
                newCategoryList.push(product);
            }            
        }

        if(newCategoryList.length === 0){
            continue;
        };

        category.list = newCategoryList;

        newList.push(category);
    }

    setList(newList);
  }

  function searchValid() {
    return searchNeedle !== null && searchNeedle.trim() !== "";
  }

  function getOnlyValues(element) {
    let haystack = '';

    if (typeof element !== "object") {
      haystack = element.toString();
      return haystack;
    }

    if (props.searchIn) {
      props.searchIn.forEach((property) => {
        haystack = haystack + " " + element[property];
      });

      return haystack;
    };

    haystack = Object.values(element);
    haystack = JSON.stringify(element);

    return haystack;
  }

  const context = {
    list: list,
    original: originalList,
    change: change,
    copy: copy,
    json: json,
    search: search,
    reset: reset,
    touched: touched,
  };

  return (
    <ListService.Provider value={context}>
      {props.children}
    </ListService.Provider>
  );
}
