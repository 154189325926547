import ProdutosCard from "../../Produtos/ProdutosCard/ProdutosCard";

const CategoriaLista = ({ category, openProduct }) => {
  return (
    <>
      <div className={category.list.length === 0 ? 'd-none' : ''}>
        <h2 className="h2 fw-bold fsc-m-1p5 px-3 px-lg-0">{category.name}</h2>

        <div className="row mx-0">
          {category.list.map((product, index) => {
            return <ProdutosCard product={product} key={index} openProduct={openProduct} />;
          })}
        </div>
      </div>
    </>
  );
};

export default CategoriaLista;
