import { useState } from "react";
import { createContext } from "react";
import Price from "../../misc/classes/Filters/Price";
import CartAlerts from "./CartAlerts/CartAlerts";
import { useRef } from "react";

const CartList = createContext({
  list: [],
  total: { number: 0, formatted: "R$ 0,00" },
  quantity: 0,
  delivery: {number: 7, formatted: "R$ 7,00"},
  changeDelivery: (entrega) => {},
  add: (product) => {},
  remove: (index) => {},
  edit: (index, product) => {},
  openEdit: (product, index) => {},
  copy: () => {},
  json: () => {},
  reset: () => {},
  cupom: null,
  setCupom: (cupom) => {},
  getDiscount: () => {}
});
export default CartList;

export function CartListProvider({children, editProduct}) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState({ number: 0, formatted: "R$ 0,00" });
  const [quantity, setQuantity] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState({number: 7, formatted: "R$ 7,00"});
  const alertsRef = useRef();
  const [cupom, setCupom] = useState(null);

  function add(product) {
    const newList = copy();
    const productPrice = product.final_price;
    const productQtd = product.quantity;

    newList.push(product);

    setList(newList);
    definePrice(productPrice, true);
    setQuantity(quantity + productQtd);
    alertsRef.current.showAdd();
  }

  function remove(index) {
    const newList = copy();
    const productPrice = newList[index].final_price;
    const productQtd = newList[index].quantity;

    newList.splice(index, 1);

    setList(newList);
    definePrice(productPrice, false);
    setQuantity(quantity - productQtd);
    alertsRef.current.showDelete();
  }

  function edit(index, product) {
    const newList = copy();
    const oldProduct = newList[index];

    const newPrice = product.final_price - oldProduct.final_price;

    const oldQtd = oldProduct.quantity;
    const productQtd = product.quantity;

    const newQtd = (quantity - oldQtd) + productQtd;
    
    newList[index] = product;

    setList(newList);
    definePrice(newPrice, true);
    setQuantity(newQtd);
    alertsRef.current.showEdit();
  }

  function definePrice(number, toAdd) {
    let newPrice = total.number ;

    if (toAdd) {
      newPrice = newPrice + number;
    } else {
      newPrice = newPrice - number;
    }

    const priceObject = {
      number: newPrice,
      formatted: Price.numberToCurrency(newPrice),
    };

    setTotal(priceObject);
  }

  function changeDelivery(entrega = 0){
    const newDelivery = {
        number: entrega === 0 ? 7 : 0,
        formatted: entrega === 0 ? 'R$ 7,00' : 'R$ 0,00'
    };

    setDeliveryFee(newDelivery);
  }

  function reset(){
    setList([]);
    setTotal({ number: 0, formatted: "R$ 0,00" });
    setQuantity(0);
    setDeliveryFee({number: 7, formatted: "R$ 7,00"});
    setCupom(null);
  }

  function copy() {
    return [...list];
  }

  function json() {
    return JSON.stringify(copy());
  }

  function _setCupom(cupom){
    setCupom(cupom);
  }

  function getDiscount(){
    if(cupom === null) return 0;

    if(cupom.type === 'percentage'){
        const discount = (total.number + deliveryFee.number) * (cupom.discount / 100);
        return discount;
    }

    if(cupom.type === 'fixed'){
        return cupom.discount;
    }

    if(cupom.type === 'delivery'){
        return deliveryFee.number;
    }
  }

  const context = {
    list,
    total,
    quantity,
    delivery: deliveryFee,
    changeDelivery,
    add,
    remove,
    edit,
    openEdit: editProduct,
    copy,
    json,
    reset,
    cupom,
    setCupom: _setCupom,
    getDiscount
  };

  return (
    <CartList.Provider value={context}>
        {children}

        <CartAlerts ref={alertsRef} />
    </CartList.Provider>
  );
}
