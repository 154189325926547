import { CardPayment, initMercadoPago } from "@mercadopago/sdk-react";
import { useContext, useEffect, useRef } from "react";
import Modal from "../../../../components/Layout/Modal/Modal";
import AuthService from "../../../Auth/Auth";
import CartList from "../../../Cart/CartList";

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY);

const ModalCardBrick = ({ onSuccess }) => {
  const cart = useContext(CartList);
  const auth = useContext(AuthService);
  const modalRef = useRef();

  useEffect(() => {
    return () => {
      if (window.cardPaymentBrickController) {
        window.cardPaymentBrickController.unmount();
      }
    };
  }, []);

  const initialization = {
    amount:
      cart.total.number === 0 ? 1 : cart.total.number + cart.delivery.number,
  };

  if (process.env.NODE_ENV !== "production") {
    initialization.payer = {
      email: "teste@teste.com.br",
      identification: {
        type: "CPF",
        number: "12345678909",
      },
    };

    console.log({
      card_test: {
        number: "5031433215406351",
        cvv: "123",
        validity: "11/25",
        status: {
          APRO: "Pagamento aprovado",
          OTHE: "Recusado por erro geral",
          CONT: "Pagamento pendente	",
          CALL: "Recusado com validação para autorizar",
          FUND: "Recusado por quantia insuficiente	",
          SECU: "Recusado por código de segurança inválido",
          EXPI: "Recusado por problema com a data de vencimento",
          FORM: "Recusado por erro no formulário",
        },
        reference:
          "https://www.mercadopago.com.br/developers/pt/docs/checkout-bricks/additional-content/your-integrations/test/cards",
      },
    });
  }

  if (auth.user) {
    initialization.payer = {
      email: auth.user.email,
      identification: {
        type: auth.user.document.length > 11 ? "CNPJ" : "CPF",
        number: auth.user.document,
      },
    };
  }

  const customization = {
    visual: {
      hideFormTitle: true,
      hidePaymentButton: true,
      style: {
        theme: "bootstrap",
      },
    },
    paymentMethods: {
      types: {
        excluded: ["debit_card"],
      },
      maxInstallments: 1,
    },
  };

  const onError = async (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    try {
      const cardFormData =
        await window.cardPaymentBrickController.getFormData();

      if (cardFormData === null) {
        return;
      }

      const additionalData =
        await window.cardPaymentBrickController.getAdditionalData();

      if (additionalData === null) {
        return;
      }

      cardFormData.first_digits = additionalData.bin.slice(0, 4);

      modalRef.current.close();
      onSuccess(cardFormData);
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        id="card-form"
        ref={modalRef}
        isStatic={true}
        title="Cartão de Crédito"
      >
        <div>
          <CardPayment
            initialization={initialization}
            customization={customization}
            onError={onError}
          />
        </div>

        <div className="mt-4">
          <button
            className="btn btn-success fw-bold w-100 py-3"
            onClick={onSubmit}
          >
            Selecionar
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ModalCardBrick;
