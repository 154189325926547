const TermosDeUso = (props) => {
  return (
    <>
      <div className="container py-5">
        <h1>Termos de Uso do Site Restaurante LeVolpi</h1>

        <p>
          Bem-vindo ao site do Restaurante LeVolpi. Ao acessar e utilizar nosso
          site, você concorda em cumprir estes Termos de Uso. Por favor, leia
          atentamente antes de prosseguir.
        </p>

        <h4>Aceitação dos Termos de Uso</h4>

        <p>
          Ao acessar e utilizar nosso site, você concorda expressamente em
          cumprir estes Termos de Uso, juntamente com todas as leis e
          regulamentos aplicáveis. Se você não concordar com estes termos, por
          favor, não utilize nosso site.
        </p>

        <h4>Descrição do Site</h4>
        <p>
          O site do Restaurante LeVolpi permite que você faça pedidos online e
          realize pagamentos. Ele também possui um banco de dados próprio que
          coleta informações não sensíveis dos usuários, a fim de aprimorar
          nossos serviços. Nosso banco de dados utiliza sistemas de ponta para
          garantir a segurança das informações.
        </p>

        <h4>Uso Autorizado</h4>
        <p>
          Ao utilizar nosso site, você declara que é maior de idade e possui
          capacidade legal para celebrar contratos. Você concorda em utilizar o
          site apenas para fins legais e de acordo com estes Termos de Uso.
          Qualquer uso não autorizado ou ilegal do site é estritamente proibido.
        </p>

        <h4>Propriedade Intelectual</h4>
        <p>
          O site do Restaurante LeVolpi e todo o seu conteúdo, incluindo textos,
          gráficos, logotipos, imagens e software, são de propriedade exclusiva
          do Restaurante LeVolpi e estão protegidos por leis de direitos
          autorais e outras leis de propriedade intelectual. É proibida a
          reprodução, distribuição ou modificação do conteúdo sem a autorização
          prévia por escrito do Restaurante LeVolpi.
        </p>

        <h4>Coleta e Uso de Informações</h4>
        <p>
          Ao utilizar nosso site, você concorda com a coleta e o uso de
          informações não sensíveis, como nome, endereço de e-mail e número de
          telefone, pelo Restaurante LeVolpi. Essas informações serão utilizadas
          para processar seus pedidos, melhorar nossos serviços e enviar
          comunicações relacionadas ao site. Respeitamos sua privacidade e
          adotamos medidas de segurança de ponta para proteger suas informações.
        </p>

        <h4>Segurança e Responsabilidade do Usuário</h4>
        <p>
          Você é responsável por manter a segurança de suas credenciais de
          acesso ao site. Qualquer atividade realizada em sua conta será de sua
          inteira responsabilidade. Você concorda em não realizar qualquer
          atividade que possa comprometer a segurança do site ou interferir no
          seu funcionamento adequado.
        </p>

        <h4>Limitação de Responsabilidade</h4>
        <p>
          O Restaurante LeVolpi não se responsabiliza por danos, perdas ou
          lesões decorrentes do uso ou incapacidade de uso do site. Nosso
          objetivo é fornecer um serviço confiável e seguro, e implementamos
          medidas de segurança de ponta para proteger suas informações no banco
          de dados.
        </p>

        <h4>Modificações nos Termos de Uso</h4>
        <p>
          O Restaurante LeVolpi se reserva o direito de modificar estes Termos
          de Uso a qualquer momento, a seu critério. Quaisquer alterações serão
          publicadas no site e entrarão em vigor imediatamente. É
          responsabilidade do usuário revisar periodicamente os Termos de Uso
          para estar ciente de quaisquer atualizações.
        </p>

        <h4>Rescisão</h4>
        <p>
          O Restaurante LeVolpi pode rescindir ou suspender seu acesso ao site a
          qualquer momento, sem aviso prévio, em caso de violação destes Termos
          de Uso ou por qualquer outro motivo.
        </p>

        <p>
          Ao utilizar nosso site, você concorda em cumprir estes Termos de Uso e
          todas as políticas e diretrizes incorporadas por referência. Caso não
          concorde com algum ponto, recomendamos que você interrompa
          imediatamente o uso do site do Restaurante LeVolpi.
        </p>

        <p>
          Estes Termos de Uso são regidos pelas leis do Brasil. Em caso de
          qualquer disputa relacionada a estes Termos de Uso, as partes
          concordam em se submeter à jurisdição exclusiva dos tribunais
          competentes na cidade de Avaré, estado de São Paulo.
        </p>

        <p>
          Se você tiver alguma dúvida ou preocupação sobre estes Termos de Uso,
          entre em contato conosco pelo seguinte endereço de e-mail:
          contato@levolpi.com.br.
        </p>

        <p>Obrigado por usar o site do Restaurante LeVolpi!</p>
      </div>
    </>
  );
};

export default TermosDeUso;
