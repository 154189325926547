import { useRef } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";

const OnlinePayment = ({payment, setPaymentVariables}) => {
    const modalOpenBtn = useRef();

  function selectPix() {
    setPaymentVariables("DIRECT", "pix");
  }

  function selectCredit() {
    setPaymentVariables("DIRECT", "credit");

    if (!payment.informations.card) {
      modalOpenBtn.current.click();
    }
  }

  return (
    <>
      <div
        className={
          "px-4 py-4 border rounded mt-4 cursor-pointer card_shadow " +
          (payment.method === "pix" && "border-success")
        }
        onClick={selectPix}
      >
        <div className="row py-1 align-items-center">
          <div className="col-auto">
            <img
              alt=""
              src="/img/pagamento/pix.png"
              style={{ width: "35px" }}
            />
          </div>
          <div className="col">
            <div>Pague com Pix</div>
            <div className="opacity-7">
              Use o QR Code ou copie e cole o código
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "px-4 py-4 border rounded mt-4 cursor-pointer card_shadow " +
          (payment.method === "credit" && "border-success")
        }
        onClick={selectCredit}
      >
        <div className="row py-1 align-items-center">
          <div className="col-auto fsc-2 text-success">
            <FontAwesome icon="credit-card" />
          </div>
          <div className="col">
            <div>Pague com cartão de crédito</div>
            <div className="row">
              <div className="col opacity-7">
                {payment.informations &&
                payment.informations.token &&
                payment.informations.payer &&
                payment.informations.first_digits ? (
                  <>
                    Cartão final <b>{payment.informations.first_digits}</b>
                  </>
                ) : (
                  <>Informe o cartão</>
                )}
              </div>
              <div
                className="col-auto text-success"
                data-bs-toggle="modal"
                data-bs-target="#card-form"
                ref={modalOpenBtn}
              >
                Trocar cartão
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center fsc-n8 mt-5">
        <div className="col text-secondary">
          <div>
            <span className="me-2 text-success">
              <FontAwesome icon="lock" />
            </span>{" "}
            O pagamento será processado através da plataforma{" "}
            <span className="text-success fw-500">MercadoPago</span>.
          </div>
          <div>
            <span className="me-2 text-success">
              <FontAwesome icon="lock" />
            </span>{" "}
            Ambiente assegurado pelas mais rígidas normas de segurança digital.
          </div>
        </div>
        <div className="col-auto ps-0">
          <img src="/img/pagamento/mercadopago.png" width={80} alt="" />
        </div>
      </div>
    </>
  );
};

export default OnlinePayment;
