import { useRef, useState } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";

const NovaSenha = ({ onSuccess, id, code }) => {
  const passRef = useRef();
  const [showNewPass, setShowNewPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setErrors(null);

    HttpRequest.post(
      "/trocar-senha",
      JSON.stringify({ code: code, id: id, password: passRef.current.value }),
      onSuccessSubmit,
      onError
    );
  };

  const toggleNewPass = () => {
    setShowNewPass(!showNewPass);
  };

  const onSuccessSubmit = () => {
    onSuccess();
    setLoading(false);
  };

  const onError = (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }

    setErrors(error);

    setLoading(false);
  };

  return (
    <>
      <p className="pb-0">Defina uma nova senha para sua conta.</p>

      <form className="mt-5" onSubmit={onSubmit}>
        <div className="form-group">
          <label>Nova senha</label>
          <div className="row align-items-center">
            <div className="col">
              <input
                className="form-control"
                required
                ref={passRef}
                type={showNewPass ? "text" : "password"}
              />
            </div>
            <div
              className="col-auto text-secondary cursor-pointer"
              onClick={toggleNewPass}
            >
              {showNewPass ? (
                <FontAwesome icon="eye-slash" />
              ) : (
                <FontAwesome icon="eye" />
              )}
            </div>
          </div>
        </div>

        {errors && (
          <div className="alert alert-danger fsc-n9 mt-4">{errors.message}</div>
        )}

        {loading && (
          <div className="btn btn-success w-100 py-2 mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}

        {!loading && (
          <>
            <button className="w-100 btn btn-success py-3 fw-bold mt-4">
              Salvar
            </button>
          </>
        )}
      </form>
    </>
  );
};

export default NovaSenha;
