import { useEffect } from "react";
import { useState } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";
import { useContext } from "react";
import AuthService from "../Auth/Auth";
import { useNavigate } from "react-router-dom";
import FontAwesome from "../../components/Layout/FontAwesome";
import { NavLink } from "react-router-dom";

const Pedidos = (props) => {
  const auth = useContext(AuthService);
  const [loading, setLoading] = useState(true);
  const [pedidos, setPedidos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.init) {
      if (auth.user.jwt) {
        getList();
      } else {
        onError();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.init]);

  function getList() {
    const body = { jwt: auth.user.jwt };

    setLoading(true);

    HttpRequest.post(
      "/pedidos/listar",
      JSON.stringify(body),
      onGetOrders,
      onError
    );
  }

  function onGetOrders(response) {
    setPedidos(response.list);
    setLoading(false);
  }

  function onError() {
    navigate("/");
    return;
  }

  return (
    <>
      <div className="container pt-5">
        <h3>Meus Pedidos</h3>

        {pedidos.length === 0 && !loading && (
          <>
            <div className="text-center">
              <div className="text-success fsc-2">
                <FontAwesome icon="receipt" />
              </div>
              <div className="my-4 fw-light fsc-1p2 opacity-7">
                Nenhum pedido encontrado.
              </div>

              <NavLink to={"/"}>
                <div className="px-5 py-3 fw-bold btn btn-success">
                  Ver Cardápio
                </div>
              </NavLink>
            </div>
          </>
        )}

        {pedidos.length > 0 && !loading && (
          <>
            <div className="row mt-lg-5 mt-4">
              {pedidos.map((pedido, index) => {
                return (
                  <div className="col-lg-6 col-12 mb-4" key={index}>
                    <div className="rounded card_shadow border p-4">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-lg-auto col-12">
                          <div className="fw-light text-success">
                            {" "}
                            <span className="opacity-7 me-2">
                              <FontAwesome icon="receipt" />
                            </span>{" "}
                            Pedido {pedido.id}
                          </div>
                        </div>
                        <div className="col-auto fw-light opacity-7">
                          <div className="rounded p-1 fw-light bg-light">
                            {pedido.status === "COMPLETED" && "Concluído"}
                            {pedido.status === "DELIVERING" && "Rota de Entrega"}
                            {pedido.status === "WAITING_DELIVER" && pedido.address !== null &&  "Aguardando Entregador"}
                            {pedido.status === "WAITING_DELIVER" && pedido.address === null &&  "Aguardando Retirada"}
                            {(pedido.status === "PREPARING" || pedido.status === "PAID") && "Preparando"}
                            {pedido.status === "ANALYZING" && "Em Análise"}
                            {pedido.status === "PROCESSING" && "Aguardando Pagamento"}
                            {pedido.status === "CANCELED" && "Cancelado"}
                          </div>
                        </div>
                        <div className="col-auto fw-light opacity-7">
                          {pedido.date}
                        </div>
                      </div>

                      <hr className="opacity-1" />

                      {pedido.items.map((item, indexItem) => {
                        return (
                          <div className="row mb-1" key={indexItem}>
                            <div className="col-auto">
                              <div
                                className="rounded py-1 px-2"
                                style={{
                                  backgroundColor: "var(--bs-gray-200)",
                                }}
                              >
                                {item.quantity}
                              </div>
                            </div>
                            <div className="col pt-1 ps-0 opacity-7">
                              {item.name}
                            </div>
                          </div>
                        );
                      })}

                      <hr className="opacity-1" />

                      <div className="row align-items-center justify-content-between">
                        <div className="col-auto fw-light opacity-6 fsc-1p2">
                          {pedido.total}
                        </div>
                        <div className="col-auto">
                          <NavLink to={"/pedidos/" + pedido.id}>
                            <div className="btn btn-outline-success fw-light">
                              Detalhes
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {loading && (
          <>
            <div className="row mx-0 w-100 mt-lg-5 mt-4 opacity-6 placeholder-glow">
              <div className="col-lg-6 col-12">
                <div className="w-100 py-5 placeholder rounded bg-success">
                  <div className="py-5"></div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="w-100 py-5 placeholder rounded bg-success">
                  <div className="py-5"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Pedidos;
