import { useContext, useRef } from "react";
import CSS from "./CartButton.module.css";
import FontAwesome from "../../FontAwesome";
import CartList from "../../../../Paginas/Cart/CartList";
import { useNavigate } from "react-router-dom";
import Price from "../../../../misc/classes/Filters/Price";

const CartButton = () => {
  const cart = useContext(CartList);
  const navigate = useNavigate();
  const openModalRef = useRef();

  function openCart(){
    if(cart.quantity === 0){
        return;
    };

    const modalElement = document.getElementById('cart');

    if(modalElement){
        openModalRef.current.click();
    } else {
        navigate('/finalizar-pagamento');
    };
  }

  return (
    <>
      <div
        className={"px-3 py-2 rounded-pill fsc-n8 " + CSS.nav_button + ' ' + (cart.quantity > 0 && CSS.filled ) }
        onClick={openCart}
      >
        <div className="hidden" style={{width: 0, height: 0, fontSize: 0}} data-bs-toggle='modal' data-bs-target='#cart' ref={openModalRef}></div>
        <div className="row mx-0 align-items-center justify-content-between">
          <div className="col-auto pe-lg-1 fsc-1p5">
            <FontAwesome icon="cart-shopping" />
          </div>
          <div className="col-auto d-lg-none">
            Ver Carrinho
          </div>
          <div className="col-auto" style={{lineHeight: '1.3em'}}>
            <div>{ cart.total.number > 0 ? Price.numberToCurrency((cart.total.number + cart.delivery.number) - cart.getDiscount()) : 'R$ 0,00' }</div>
            <div className="d-none d-lg-block">
              {cart.quantity}{" "}
              {cart.quantity === 1 ? "item" : "itens"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartButton;
