import { useContext } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import { useRef } from "react";
import NavItem from "./NavItem";
import CSS from "./CategoriasNav.module.css";
import FontAwesome from "../../../../components/Layout/FontAwesome";

const CategoriasNavMobile = (props) => {
    const listService = useContext(ListService);
    const scrollDivRef = useRef();

  return (
    <>
      <div className={CSS.nav_mobile + " d-lg-none"}>
        <div className={CSS.nav_outer} ref={scrollDivRef} id="cardapio-nav">
          <div className={CSS.nav_tab}>
            {listService.list.map((category, index) => {
                if(category.list.length === 0) return null;

              return (
                <NavItem
                  key={index}
                  category={category}
                  index={index}
                  parent={scrollDivRef}
                >
                  {index}
                </NavItem>
              );
            })}
            {listService.list.length === 0 && (
                <div className={CSS.nav_link}>Parece que não há itens. <FontAwesome icon='face-sad-cry'/></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriasNavMobile;
