import FontAwesome from "../../../components/Layout/FontAwesome";

const CardDeclined = (props) => {
  return (
    <>
      <div className="text-center p-4 p-lg-5">
        <div className="fsc-3 text-danger">
          <FontAwesome icon="face-frown" />
        </div>

        <h4 className="mt-4 mb-5 fw-light">Oops...</h4>

        <div className="rounded p-3 bg-light border text-center">
          <div>A compra foi recusada pela operadora do cartão.</div>
          <div className="fw-light fsc-n9 mt-2">
            Por favor, revise as informações do cartão, ou tente novamente utilizando outro método de pagamento.
          </div>
        </div>

        <div
          className="btn btn-success mt-3 w-100 fw-bold py-3"
          data-bs-dismiss="modal"
        >
          Tentar novamente
        </div>
      </div>
    </>
  );
};

export default CardDeclined;
