import { useState } from "react";
import CSS from "./App.module.css";
import LoadingShield from "./components/Layout/LoadingShield/LoadingShield";
import Nav from "./components/Layout/Nav/Nav";
import { ListServiceProvider } from "./components/shared/Lists/ListService";
import RoutesList from "./routes/RoutesList";
import { useEffect } from "react";
import HttpRequest from "./misc/classes/HttpRequest/HttpRequest";
import { ModalProdutosProvider } from "./Paginas/ModalProdutos/ModalProdutosContext";
import { CartListProvider } from "./Paginas/Cart/CartList";
import { useRef } from "react";
import Configurations from "./hooks/Configurations/Configurations";

function App() {
  const [list, setList] = useState([]);
  const modalContext = useRef();

  useEffect(() => {
    getCardapio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCardapio() {
    HttpRequest.get("/cardapio", onSuccess);
  }

  function onSuccess(response) {
    setTimeout(() => {
      setList(response.cardapio);
    }, 0);
  }

  function editProduct(product, index) {
    modalContext.current.edit(product, index);
  }

  return (
    <>
      <Configurations>
        <ListServiceProvider list={list} searchIn={["name", "description"]}>
          <CartListProvider editProduct={editProduct}>
            <ModalProdutosProvider ref={modalContext}>
              <LoadingShield state={list.length > 0} />
              <Nav />
              <RoutesList />
              <div className={CSS.bottom_spacer}></div>
            </ModalProdutosProvider>
          </CartListProvider>
        </ListServiceProvider>
      </Configurations>
    </>
  );
}

export default App;
