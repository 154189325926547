import { createContext } from "react";
import ModalUser from "./ModalUser/ModalUser";
import { useRef } from "react";
import { useContext } from "react";
import AuthService from "../Auth";
import ModalRegister from "./ModalRegister/ModalRegister";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";

const UserContext = createContext({
  open: () => {},
  update: {
    personal: ({ name, document }, onSuccess, onError) => {},
    contact: (tel, onSuccess, onError) => {},
    credential: ({oldPass, newPass}, onSuccess, onError) => {},
  },
});

export default UserContext;

export const User = ({ children }) => {
  const auth = useContext(AuthService);
  const modalUserRef = useRef();
  const modalRegisterRef = useRef();

  function open() {
    if (auth.user) {
      modalUserRef.current.open();
    } else {
      modalRegisterRef.current.open();
    }
  }

  function update(url, body, onSuccess, onError) {
    body.jwt = auth.user.jwt;

    body = JSON.stringify(body);

    HttpRequest.post("/usuarios/dados" + url, body, (response)=>{ updateUserContext(response); onSuccess(); }, onError);
  }

  function updatePersonal({ name, document }, onSuccess, onError) {
    let body = { name, document };

    update("/pessoais", body, onSuccess, onError);
  }

  function updateContact(tel, onSuccess, onError) {
    let body = {...tel};

    update("/contato", body, onSuccess, onError);
  }

  function updateCredential({ oldPass, newPass }, onSuccess, onError) {
    let body = { oldPass, newPass };

    update("/credenciais", body, onSuccess, onError);
  }

  function updateUserContext(response){
    auth.updateUser(response.user);    
  }

  const context = {
    open,
    update: {
      personal: updatePersonal,
      contact: updateContact,
      credential: updateCredential,
    },
  };

  return (
    <>
      <UserContext.Provider value={context}>
        {children}

        {auth.logged && <ModalUser ref={modalUserRef} />}

        {!auth.logged && <ModalRegister ref={modalRegisterRef} />}
      </UserContext.Provider>
    </>
  );
};
