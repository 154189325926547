import FontAwesome from "../../../components/Layout/FontAwesome";
import CSS from "./ShieldProcessing.module.css";

const ShieldProcessing = (props) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center p-5">
        <div className="">
          <div className="text-center">
            <div>
              <img
                src="/img/logo/logo-full-small.png"
                alt=""
                style={{ width: 100 }}
              />
            </div>

            <div className="fw-light my-5">
              Por favor, aguarde enquanto<br/>estamos processando seu pedido.
            </div>

            <div
              className={CSS.loading_icon + " text-success fsc-3 fsc-m-2"}
            >
              <FontAwesome type="solid" icon="utensils" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShieldProcessing;
