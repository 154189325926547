import { useEffect } from "react";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";
import CategoriasNav from "../Categorias/Nav/CategoriasNav";
import CSS from "./NavAndSearch.module.css";
import { useRef } from "react";
import CategoriasNavMobile from "../Categorias/Nav/CategoriasNavMobile";
import SearchCardapioMobile from "./SearchCardapioMobile";

const NavAndSearch = (props) => {
  const divRef = useRef();

  useEffect(() => {
    window.addEventListener("scroll", addClassSticky);

    return () => {
      window.removeEventListener("scroll", addClassSticky);
    };
  });

  function addClassSticky() {
    var scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPosition > 100) {
      divRef.current.classList.add("sticky");
    } else {
      divRef.current.classList.remove("sticky");
    }
  }

  return (
    <>
      <div ref={divRef} className={CSS.nav_and_search}>
        <div className={CSS.logo}>
          <img
            alt="logo"
            src="img/logo/logo-full-small.png"
          />
        </div>

        <div className="container">
          <div
            className={"row align-items-center d-none d-lg-flex py-2"}
            ref={divRef}
          >
            <div className="col-5">
              <CategoriasNav />
            </div>
            <div className="col-7">
              <SearchList />
            </div>
          </div>
        </div>
      </div>

      <CategoriasNavMobile />

      <SearchCardapioMobile />
    </>
  );
};

export default NavAndSearch;
