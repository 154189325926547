import FontAwesome from "../../../../components/Layout/FontAwesome";

const NotDefined = ({hide}) => {
   return (
       <>
           <div className="alert alert-danger fsc-n9 py-1">
            <div className="row align-items-center">
              <div className="col-auto">
                <FontAwesome icon="exclamation-circle" />
              </div>
              <div className="col">
                Por favor, escolha o método de pagamento.
              </div>
              <div
                className="col-auto fsc-1p5 cursor-pointer"
                onClick={hide}
              >
                <FontAwesome icon="times" />
              </div>
            </div>
          </div>
       </>
   )
}

export default NotDefined;