import { useState } from "react";
import { useRef } from "react";
import CSS from "./ProdutosInfo.module.css";
import FontAwesome from "../../../components/Layout/FontAwesome";
import { useEffect } from "react";
import ProdutosSubgroup from "../ProdutosSubgroup/ProdutosSubgroup";

const ProdutosInfo = ({ product, onUpdate, edit, defaultValue }) => {
  const commentRef = useRef();
  const [charCount, setCharCount] = useState(0);
  const [ingredients, setIngredients] = useState([]);

  function countCommentChar() {
    const length = commentRef.current.value.length;
    setCharCount(length);
  }

  useEffect(() => {
    commentRef.current.value = null;
    processIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(()=>{
    if(defaultValue){
        commentRef.current.value = defaultValue.observations;
        countCommentChar();
    }
  }, [defaultValue]);

  function processIngredients() {
    const newList =
      product.ingredients && Array.isArray(product.ingredients)
        ? [...product.ingredients]
        : [];

    newList.push({ placeholder: Date.now() });

    setIngredients([]);

    setTimeout(()=>{
        setIngredients(newList);
    }, 50);
  }

  return (
    <>
        <h4 className="d-lg-none mb-4">{product.name}</h4>

      {product.description && (
        <div className="opacity-7 mb-lg-4 mb-3 fsc-m-n8">
          {product.description}
        </div>
      )}

      {product.people !== null && product.people > 0 && (
        <div className="my-3 fw-500 mb-lg-4 my-4 fsc-m-n8">
          <FontAwesome icon="users" />{" "}
          <span className="ms-2">Serve {product.people} pessoas</span>
        </div>
      )}

      <div className="my-3 fw-bold fsc-1p2 mb-lg-5 my-4">
        R$ {product.price_formated}
      </div>

      {ingredients.map((subgroup, index) => {
        return ingredients.length === index + 1 ? (
          ""
        ) : (
          <ProdutosSubgroup
            product={product}
            subgroup={subgroup}
            key={index}
            onUpdate={onUpdate}
            index={index}
          />
        );
      })}

      <div className="form-group mt-5 fsc-m-n8 mb-0 ">
        <div className="row opacity-5">
          <div className="col fsc-1p2">
            <label>Algum comentário?</label>
          </div>
          <div className="col-auto fw-light">{charCount}/200</div>
        </div>

        <textarea
          className={"form-control fsc-1 " + CSS.textarea}
          ref={commentRef}
          id="costumer-observations"
          placeholder="Ex: tirar a cebola, maionese à parte, etc."
          maxLength={200}
          onChange={countCommentChar}
        ></textarea>
      </div>
    </>
  );
};

export default ProdutosInfo;
