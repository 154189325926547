import { useContext } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import NavService from "./NavService";
import { useRef } from "react";
import { useEffect } from "react";

const CategoriasNav = () => {
  const listService = useContext(ListService);
  const navService = useContext(NavService);
  const selectRef = useRef();

  useEffect(() => {
    selectRef.current.value = navService.active;
  }, [navService.active]);

  function goTo() {
    const value = selectRef.current.value;

    if (value === "") return;

    navService.navClick();
    navService.scrollNav(value);
    navService.scrollPage(value);
    navService.setActive(value);
  }

  return (
    <>
      <div
        className="d-none d-lg-block position-sticky mb-0 h-100"
        style={{ top: 0 }}
      >
        <select
          className="form-select fw-bold h2 p-3 styless border mb-0 cursor-pointer h-100"
          defaultValue={navService.active}
          onChange={goTo}
          placeholder="Selecione a categoria"
          ref={selectRef}
        >
          {listService.list.length === 0 && <option value="">-</option>}

          {listService.list.map((category, index) => {
            if (category.list.length === 0) {
              return null;
            }

            return (
              <option key={index} value={index}>
                {category.name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default CategoriasNav;
