import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";
import { useContext } from "react";
import UserContext from "../Auth/User/User";

const Credenciais = (props) => {
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const oldPass = useRef();
  const newPass = useRef();
  const user = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function onSubmit(e) {
    e.preventDefault();

    resetError();
    resetSuccess();
    setUpdating(true);

    const body = {
      oldPass: oldPass.current.value,
      newPass: newPass.current.value,
    };

    user.update.credential(body, onSuccess, onError);
  }

  function onSuccess(response) {
    setUpdating(false);
    setSuccess(true);
  }

  function onError(response) {
    setUpdating(false);
    setError(response.message ? response.message : true);
  }

  function resetError() {
    setError(false);
  }

  function resetSuccess() {
    setSuccess(false);
  }

  function toggleOldPass() {
    setShowOldPass(!showOldPass);
  }

  function toggleNewPass() {
    setShowNewPass(!showNewPass);
  }

  return (
    <>
      <div className="container px-4" style={{ maxWidth: 500 }}>
        <h2 className="my-5">Credenciais</h2>

        <form onSubmit={onSubmit}>
          <div className="form-group mt-lg-5 mt-4">
            <label>Senha atual</label>

            <div className="row align-items-center">
              <div className="col">
                <input
                  className="form-control"
                  required
                  ref={oldPass}
                  type={showOldPass ? "text" : "password"}
                />
              </div>
              <div
                className="col-auto text-secondary cursor-pointer"
                onClick={toggleOldPass}
              >
                {showOldPass ? (
                  <FontAwesome icon="eye-slash" />
                ) : (
                  <FontAwesome icon="eye" />
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Nova senha</label>
            <div className="row align-items-center">
              <div className="col">
                <input
                  className="form-control"
                  required
                  ref={newPass}
                  type={showNewPass ? "text" : "password"}
                />
              </div>
              <div
                className="col-auto text-secondary cursor-pointer"
                onClick={toggleNewPass}
              >
                {showNewPass ? (
                  <FontAwesome icon="eye-slash" />
                ) : (
                  <FontAwesome icon="eye" />
                )}
              </div>
            </div>
          </div>

          {error && (
            <div className="alert alert-danger fsc-n8 mb-4">
              <div className="row">
                <div className="col">
                    {error === true ? <>Desculpe, mas ocorreu um erro. Tente novamente mais tarde.</> : error}
                </div>
                <div
                  className="col-auto fsc-1p2 cursor-pointer"
                  onClick={resetError}
                >
                  <FontAwesome icon="times" />
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="alert alert-success fsc-n8 mb-4">
              <div className="row">
                <div className="col">Informações atualizadas com sucesso!</div>
                <div
                  className="col-auto fsc-1p2 cursor-pointer"
                  onClick={resetSuccess}
                >
                  <FontAwesome icon="times" />
                </div>
              </div>
            </div>
          )}

          <div className="row align-self-center justify-content-between">
            <div className="col-auto">
              <NavLink to={"/meus-dados"}>
                <div className="btn btn-outline-secondary fw-bold py-2 link">
                  Voltar
                </div>
              </NavLink>
            </div>
            <div className="col-auto">
              {updating && (
                <div className="text-success">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </div>
              )}
              {!updating && (
                <button type="submit" className="btn btn-success fw-bold py-2">
                  Atualizar
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Credenciais;
