import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";
import Cookies from "js-cookie";

const AuthService = createContext({
  init: false,
  logged: false,
  user: null,
  lastAddress: null,
  checkIfUserExists: (email, onExist, onNotExist) => {},
  registerUser: (user = { email: null, password: null }, onSuccess) => {},
  signOutUser: () => {},
  signInWithPassword: (email, password) => {},
  onSigin: (execFunction) => {},
  updateUser: (user) => {},
});

export default AuthService;

export const Auth = ({ children }) => {
  const [init, setInit] = useState(false);
  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState(false);
  const [onSuccessSignin, setOnSuccessSignin] = useState(null);
  const [lastAddress, setLastAddress] = useState(null);

  useEffect(() => {
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logged && onSuccessSignin) {
      executeOnSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  function autoLogin() {
    const localJWT = localStorage.getItem("jwt_credentials");
    const sessionJWT = Cookies.get("jwt_credentials");

    if (sessionJWT || localJWT) {
      loginWithJWT(sessionJWT ? sessionJWT : localJWT);
    } else {
      setInit(true);
    }
  }

  function loginWithJWT(JWT) {
    HttpRequest.post(
      "/usuarios/auth/jwt",
      JSON.stringify({ JWT }),
      (response) => {
        userIsLogged(response, true);
      },
      signOutUser
    );
  }

  function saveLocalJWT(jwt, skip = false) {
    const JWT = localStorage.getItem("jwt_credentials");

    if (skip || JWT) {
      localStorage.setItem("jwt_credentials", jwt);
    }
  }

  function saveCookieJWT(jwt) {
    Cookies.set("jwt_credentials", jwt);
  }

  function signOutUser() {
    setUser(null);
    setLogged(false);
    clearJWT();
    setInit(true);
  }

  function clearJWT() {
    localStorage.removeItem("jwt_credentials");
    Cookies.remove("jwt_credentials");
  }

  function checkIfUserExists(email, onExist, onNotExist, onError) {
    HttpRequest.get(
      "/usuarios/email/existe?email=" + email,
      onExist,
      (response) => {
        if (response.status === 404) {
          onNotExist();
        } else {
          onError(response);
        }
      }
    );
  }

  function registerUser(user, onSuccess, onError) {
    HttpRequest.post(
      "/usuarios/registrar",
      JSON.stringify(user),
      (response) => {
        userIsLogged(response);
        onSuccess();
      },
      onError
    );
  }

  function userIsLogged(response, local = false, skip = false) {
    let newUser = { ...response.user };
    newUser.jwt = response.jwt;
    setUser(newUser);
    setLogged(true);
    saveCookieJWT(response.jwt);
    if (local) {
      saveLocalJWT(response.jwt, skip);
    }
    if (response.last_address) {
      setLastAddress(response.last_address);
    }
    setInit(true);
  }

  function signInWithPassword(email, password, onSuccess, onError) {
    const body = JSON.stringify({ email, password });

    HttpRequest.post(
      "/usuarios/auth",
      body,
      (response) => {
        userIsLogged(response);
        onSuccess();
      },
      onError
    );
  }

  function executeOnSuccess() {
    onSuccessSignin();
    setOnSuccessSignin(null);
  }

  function onSigin(execFunction) {
    setOnSuccessSignin(() => execFunction);
  }

  function updateUser(newUser) {
    setUser({ ...newUser, jwt: user.jwt });
  }

  const context = {
    init,
    logged,
    user,
    lastAddress,
    checkIfUserExists,
    registerUser,
    signOutUser,
    signInWithPassword,
    onSigin,
    updateUser,
  };

  return (
    <AuthService.Provider value={context}>{children}</AuthService.Provider>
  );
};
