const Pagamento = ({ pedido }) => {
  return (
    <>
      <div className="mb-4">
        <h4 className="opacity-5 text-success fsc-1p2">Pagamento</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <>
            <div className="fsc-1p2 fsc-m-1 fw-light">
              {pedido.payment === "LOCAL" && <div>Pagamento na entrega</div>}

              {pedido.payment === "DIRECT" && (
                <>
                  <div>
                    Pagamento pelo site -{" "}
                    {pedido.payment_method === "credit" && <>Crédito</>}
                    {pedido.payment_method === "pix" && <>Pix</>}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Pagamento;
