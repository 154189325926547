import { useEffect } from "react";
import { useState } from "react";

const ItemIngredients = ({ ingredients, subgroups }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    proccessIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients]);

  function proccessIngredients() {
    const newList = [];

    ingredients.forEach((subgroup, groupIndex) => {
      if (subgroup.length > 0) {
        const subgroupArray = reduceArray(subgroup);

        subgroupArray.forEach((e) => {
          const name = subgroups[groupIndex].ingredients[e.index].name;

          newList.push({ quantity: e.quantity, name: name });
        });
      }
    });

    setList(newList);
  }

  function reduceArray(array) {
    return array.reduce(function (acc, curr) {
      var found = acc.find(function (obj) {
        return obj.index === curr;
      });

      if (found) {
        found.quantity++;
      } else {
        acc.push({ index: curr, quantity: 1 });
      }

      return acc;
    }, []);
  }

  return (
    <>
      {list.map((e, i) => {
        return (
          <span key={i}>
            {e.quantity}x {e.name}
            {list.length > (i + 1) ? ", " : ""}
          </span>
        );
      })}
    </>
  );
};

export default ItemIngredients;
