import { useState } from "react";
import InformarEmail from "./InformarEmail";
import InformarCodigo from "./InformarCodigo";
import NovaSenha from "./NovaSenha";
import Confirmar from "./Confirmar";

const RecuperarSenha = (props) => {
  const [page, setPage] = useState(0);
  const [id, setId] = useState(null);
  const [code, setCode] = useState(null);

  const informedId = (id) => {
    setId(id);
    setPage(1);
  };

  const informedCode = (code) => {
    setCode(code);
    setPage(2);
  };

  return (
    <>
      <div className="container px-4" style={{ maxWidth: 500 }}>
        <h2 className="my-5">Recuperar Senha</h2>

        {page === 0 && <InformarEmail onSuccess={informedId} />}
        {page === 1 && (
          <InformarCodigo
            id={id}
            onSuccess={informedCode}
            onBack={() => {
              setPage(0);
            }}
          />
        )}
        {page === 2 && (
          <NovaSenha
            id={id}
            code={code}
            onSuccess={() => {
              setPage(3);
            }}
          />
        )}
        {page === 3 && <Confirmar />}
      </div>
    </>
  );
};

export default RecuperarSenha;
