import { useContext, useEffect, useRef } from "react";
import NavService from "./NavService";

const NavHash = (props) => {
  const elementRef = useRef(null);
  const navService = useContext(NavService);

  useEffect(() => {
    var isScrolling = false;

    const handleScroll = () => {
      const element = elementRef.current;
      if (!element) return;

      if (isScrolling) return;

      isScrolling = true;

      setTimeout(() => {
        isScrolling = false;
      }, 400);

      if (isInViewport()) {
        onView();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onView() {
    const parent = document.getElementById("cardapio-nav");

    if (
      !parent ||
      parseInt(parent.getAttribute("data-scrolling-end")) >= Date.now()
    ) {
      return;
    }

    navService.scrollNav(props.index);
    navService.setActive(props.index);
  }

  function isInViewport() {
    const rect = elementRef.current.getBoundingClientRect();
  
    return (
      rect.top <= 100 &&
      rect.bottom >= 0
    );
  }

  return (
    <>
      <div id={"category-" + props.index} className="pt-5" ref={elementRef}>
        <div className="pt-lg-5 pt-4"></div>
        {props.children}
      </div>
    </>
  );
};

export default NavHash;
