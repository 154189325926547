import { Auth } from "../../Paginas/Auth/Auth";
import { User } from "../../Paginas/Auth/User/User";
import { HorariosProvider } from "./Horarios";
import { PagamentosProvider } from "./Pagamentos";

const Configurations = (props) => {
  return (
    <>
      <Auth>
        <User>
          <HorariosProvider>
            <PagamentosProvider>{props.children}</PagamentosProvider>
          </HorariosProvider>
        </User>
      </Auth>
    </>
  );
};

export default Configurations;
