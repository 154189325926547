import { useContext } from "react";
import CSS from "./CartButton.module.css";
import FontAwesome from "../../FontAwesome";
import CartList from "../../../../Paginas/Cart/CartList";
import Price from "../../../../misc/classes/Filters/Price";

const CartButtonMobile = () => {
  const cart = useContext(CartList);

  return (
    <>
      {cart.quantity > 0 && (
        <div
          className={
            "px-3 fsc-n9 " +
            CSS.nav_button_mobile +
            " " +
            CSS.nav_button +
            " " +
            (cart.quantity > 0 && CSS.filled)
          }
          data-bs-toggle="modal"
          data-bs-target="#cart"
        >
          <div className="row mx-0 align-items-center justify-content-between">
            <div className="col-auto pe-lg-1 fsc-1p2">
              <FontAwesome icon="cart-shopping" />
            </div>
            <div className="col-auto d-lg-none fw-500">Ver Carrinho</div>
            <div className="col-auto fw-bold" style={{ lineHeight: "1.3em" }}>
            <div>{ cart.total.number > 0 ? Price.numberToCurrency((cart.total.number + cart.delivery.number) - cart.getDiscount()) : 'R$ 0,00' }</div>
              <div className="d-none d-lg-block">
                {cart.quantity} {cart.quantity === 1 ? "item" : "itens"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartButtonMobile;
