import { useRef, useState } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";

const InformarCodigo = ({ onSuccess, onBack, id }) => {
  const codeRef = useRef();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setErrors(null);

    HttpRequest.post(
      "/validar-codigo",
      JSON.stringify({ code: codeRef.current.value, id: id }),
      onSuccessSubmit,
      onError
    );
  };

  const onSuccessSubmit = (data) => {
    onSuccess(data.code);
    setLoading(false);
  };

  const onError = (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }

    setErrors(error);

    setLoading(false);
  };

  return (
    <>
      <p>Informe o código de recuperação que você recebeu em seu e-mail.</p>

      <div className="alert alert-warning fsc-n9">
        Caso não tenha recebido o e-mail, verifique sua caixa de spam ou lixo
        eletrônico.
      </div>

      <form className="mt-5" onSubmit={onSubmit}>
        <div className="form-group">
          <label>Código de Recuperação</label>
          <input
            className="form-control"
            required
            inputMode="numeric"
            type="text"
            pattern="[0-9]{6}"
            maxLength={6}
            minLength={6}
            ref={codeRef}
          />
        </div>
        
        {errors && (
          <div className="alert alert-danger fsc-n9 mt-4">{errors.message}</div>
        )}

        {loading && (
          <div className="btn btn-success w-100 py-2 mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}

        {!loading && (
          <>
            <button className="w-100 btn btn-success py-3 fw-bold mt-4">
              Enviar
            </button>
            <div className="text-center mt-2">
              <span className="text-success cursor-pointer" onClick={onBack}>
                Voltar
              </span>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default InformarCodigo;
