import { useState } from "react";
import Modal from "../../../../components/Layout/Modal/Modal";
import Dados from "./Dados";
import CSS from "./ModalUser.module.css";
import Contato from "./Contato";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";

const ModalUser = forwardRef((props, ref) => {
  const [page, setPage] = useState(0);
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open,
  }));

  function open() {
    goToDados();
    modalRef.current.open();
  }

  function goToDados() {
    if (page === 0) return;
    setPage(0);
  }

  function goToContato() {
    if (page === 1) return;
    setPage(1);
  }

  return (
    <>
      <Modal
        id="client-informations"
        isStatic={true}
        className={CSS.modal + " modal-lg"}
        ref={modalRef}
      >
        <div className="row mx-0">
          <div
            className={
              "col-lg-4 col-12 fsc-1p2 fsc-m-n9 pt-lg-5 pb-lg-5 px-0 text-center text-lg-start " +
              CSS.nav
            }
          >
            <div
              onClick={goToDados}
              className={
                "p-lg-4 me-4 me-lg-0 " +
                CSS.button +
                " " +
                (page === 0 && CSS.active)
              }
            >
              Meus dados
            </div>
            <div
              onClick={goToContato}
              className={
                "p-lg-4 me-4 me-lg-0 " +
                CSS.button +
                " " +
                (page === 1 && CSS.active)
              }
            >
              Dados de contato
            </div>
          </div>
          <div className="col-lg col-12 px-lg-5 p-lg-4 p-3 pt-4">
            <div className="text-end d-none d-lg-block">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {page === 0 && <Dados />}

            {page === 1 && <Contato />}
          </div>
        </div>
      </Modal>
    </>
  );
});

export default ModalUser;
