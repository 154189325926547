const Data = ({pedido}) => {
   return (
    <>
      <div className="mb-4">
        <h4 className="text-success opacity-5 fsc-1p2">Data do pedido</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <div className='fsc-1p2 fw-light fsc-m-1'>{pedido.date}</div>
        )}
      </div>
    </>
   )
}

export default Data;