import { useState } from "react";
import ProdutosIngredients from "./ProdutosIngredients";
import CSS from "./ProdutosSubgroup.module.css";
import { useEffect } from "react";

const ProdutosSubgroup = ({ product, subgroup, index, onUpdate }) => {
    const [qtd, setQtd] = useState(0);
    const [checked, setChecked] = useState();
    const [list, setList] = useState([]);

    useEffect(()=>{
        proccessChoosedIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        onUpdate(index, list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    function proccessChoosedIngredients(){
        if(!(product.choosed_ingredients && product.choosed_ingredients[index])){
            return;
        };

        const newList = product.choosed_ingredients[index];

        if(subgroup.required && subgroup.required === '1'){
            setChecked( newList[0] );
        } else {
            setQtd( newList.length );
        };

        setList(newList);
    }

    function onAdd(index, onSuccess){
        const groupQtd = parseInt(subgroup.quantity);
        
        if(typeof groupQtd !== 'number' || groupQtd === 0){
            onSuccess();
            addToList(index);
            return;
        };
        
        if(qtd < groupQtd){
            onSuccess();
            setQtd(qtd + 1);
            addToList(index);
        };
    }

    function onRemove(index, onSuccess){
        const groupQtd = parseInt(subgroup.quantity);
        
        if(typeof groupQtd !== 'number' || groupQtd === 0){
            onSuccess();
            removeFromList(index);
            return;
        };

        if(qtd > 0){
            onSuccess();
            setQtd(qtd - 1);
            removeFromList(index);
        };
    }

    function onCheck(index){
        const newList = [index];
        setChecked(index);
        setList(newList);
    }

    function addToList(index){
        const newList = [...list];
        newList.push(index);
        setList(newList);
    }

    function removeFromList(index){
        const newList = [...list];
        const realIndex = newList.indexOf(index);

        if(realIndex > -1){
            newList.splice(realIndex, 1);
        };

        setList(newList);
    }

  return (
    <>
      <div className={CSS.subgroup} id={'group-ingredients-'+index}>
        <div className={CSS.subgroup_header + " px-4 py-3"}>
          <div className="row align-items-center">
            <div className="col">
              <div className="fw-500">{subgroup.name}</div>

              {subgroup.quantity && parseInt(subgroup.quantity) > 0 && (
                <div className="fsc-n9 fw-light">
                  Escolha{" "}
                  {subgroup.required && subgroup.required === "1" ? "" : "até"}{" "}
                  {subgroup.quantity}{" "}
                  {parseInt(subgroup.quantity) > 1 ? "opções" : "opção"}
                </div>
              )}
            </div>
            {subgroup.required && subgroup.required === "1" && (
              <div className="col-auto">
                <div
                  className="p-1 rounded text-uppercase bg-secondary fsc-n6 text-white required-tag"
                  style={{ letterSpacing: ".8px" }}
                >
                  Obrigatório
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={CSS.subgroup_body}>
          {subgroup.ingredients.map((ingredient, ingreIndex) => {
            return (
              <ProdutosIngredients
                product={product}
                subgroup={subgroup}
                ingredient={ingredient}
                index={ingreIndex}
                key={ingreIndex}
                groupIndex={index}
                onAdd={onAdd}
                onRemove={onRemove}
                groupQtd={qtd}
                onCheck={onCheck}
                checked={checked}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProdutosSubgroup;
