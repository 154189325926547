import FontAwesome from "../../../components/Layout/FontAwesome";

const Error = ({ error }) => {
//   const errors = ["001", "100", "400"];
//   const internal_error = errors.indexOf(error.code) > -1;

  return (
    <>
      <div className="p-5 text-center">
        <div className="fsc-3 text-danger">
          <FontAwesome icon="face-frown" />
        </div>

        <h4 className="mt-4 mb-5 fw-light">Oops...</h4>

        {error && (
          <>
            <div className="rounded p-3 bg-light border text-center">
              <div>{error.message}</div>
              {/* {internal_error && <></>} */}
            </div>
          </>
        )}

        <div className="mt-5 fw-light fsc-n9">
          Nenhuma cobrança foi realizada.
        </div>
      </div>
    </>
  );
};

export default Error;
