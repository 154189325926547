import { useRef } from "react";
import Modal from "../../../../components/Layout/Modal/Modal";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import GetEmail from "./GetEmail";
import LogWithPass from "./LogWithPass";
import GetMeusDados from "./GetMeusDados";
import GetContato from "./GetContato";
import GetPassword from "./GetPassword";

const ModalRegister = forwardRef((props, ref) => {
  const [page, setPage] = useState(0);
  const modalRef = useRef();
  const [tempUser, setTempUser] = useState({email: null, name: null, document: null, tel: null});

  useImperativeHandle(ref, () => ({
    open,
  }));

  function open() {
    goToStart();
    modalRef.current.open();
    setTimeout(()=>{
        document.getElementById('email-register').focus();
    }, 500);
  }

  function goToStart(){
    setPage(0);
  }

  function setEmail(page, email){
    const newTempUser = {...tempUser};

    newTempUser.email = email;

    setTempUser(newTempUser);
    setPage(page);
  }

  function setMeusDados(name, document){
    const newTempUser = {...tempUser};
    newTempUser.name = name;
    newTempUser.document = document;

    setTempUser(newTempUser);
    setPage(3);
  }
  
  function backToDados(){
    setPage(2);
  }

  function setContato(tel){
    const newTempUser = {...tempUser};
    newTempUser.tel = tel;
    
    setTempUser(newTempUser);

    setPage(4);
  }

  function backToContato(){
    setPage(3);
  }

  function closeModal(){
    modalRef.current.close();
  }

  return (
    <>
      <Modal id="register-client" isStatic={true} ref={modalRef}>
        <div className="text-center">
          <img
            src="/img/logo/logo-full-small.png"
            alt=""
            style={{ width: 100 }}
          />
        </div>

        {page === 0 && <GetEmail tempUser={tempUser} onSuccess={setEmail} />}

        {page === 1 && <LogWithPass tempUser={tempUser} onCancel={goToStart} onSuccess={closeModal} />}

        {page === 2 && <GetMeusDados tempUser={tempUser} onCancel={goToStart} onSuccess={setMeusDados} />}

        {page === 3 && <GetContato tempUser={tempUser} onCancel={backToDados} onSuccess={setContato} />}

        {page === 4 && <GetPassword tempUser={tempUser} onCancel={backToContato} onSuccess={closeModal} />}
        
      </Modal>
    </>
  );
});

export default ModalRegister;
