import { useContext } from "react";
import ItemPedido from "./ItemPedido";
import CartList from "../CartList";
import FontAwesome from "../../../components/Layout/FontAwesome";

const PedidoAberto = (props) => {
  const cardapio = useContext(CartList);

  return (
    <>
      <div>
        {cardapio.list.length === 0 && (
          <div className="opacity-7 text-center my-5">
            <div className="fsc-2">
              <FontAwesome icon="cart-shopping" />
            </div>

            <div className="fw-light mt-2">
              Não há itens
              <br />
              em seu carrinho.
            </div>
          </div>
        )}

        {cardapio.list.length > 0 && (
          <>
            <div className="text-secondary mb-4">Revise seu pedido</div>
            
            {cardapio.list.map((product, index) => {
              return <ItemPedido key={index} product={product} index={index} />;
            })}
          </>
        )}
      </div>
    </>
  );
};

export default PedidoAberto;
