import { NavLink } from "react-router-dom";
import CSS from "./Nav.module.css";
import ButtonNav from "./ButtonNav";
import FontAwesome from "../FontAwesome";
import { useContext } from "react";
import AuthService from "../../../Paginas/Auth/Auth";
import CSS2 from "./NavItem.module.css";
import CartButton from "./CartButton/CartButton";

const NavDesktop = ({ signIn }) => {
  const auth = useContext(AuthService);

  return (
    <>
      <div className="container d-none d-lg-block d-print-none">
        <nav className="row align-items-center">
          <div className="col-auto py-4">
            <NavLink to="/">
              <img
                alt="logo"
                src="img/logo/logo-full-small.png"
                className={CSS.logo_menu}
              />
            </NavLink>
          </div>

          <div className="col text-secondary fsc-1p5">
            Faça Seu Pedido Online
          </div>

          <div className="col-auto d-none d-lg-block">
            <div className="row align-items-center">
              <div className="col-auto px-1">
                <ButtonNav to="/" exact={true}>
                  <FontAwesome icon="utensils" />{" "}
                  <span className="ms-2">Cardápio</span>
                </ButtonNav>
              </div>

              {auth.logged && (
                <>
                  <div className="col-auto px-1">
                    <ButtonNav to="/pedidos">
                      <FontAwesome icon="receipt" />{" "}
                      <span className="ms-2">Pedidos</span>
                    </ButtonNav>
                  </div>

                  <div className="col-auto px-1">
                    <ButtonNav to="/meus-dados">
                      <FontAwesome icon="user-cog" />{" "}
                      <span className="ms-2">Meus Dados</span>
                    </ButtonNav>
                  </div>
                </>
              )}

              {!auth.logged && (
                <div className="col-auto px-1">
                  <div
                    className={"btn btn-outline-secondary rounded-pill px-4 " + CSS2.btn}
                    onClick={signIn}
                  >
                    <FontAwesome icon="user" />{" "}
                    <span className="ms-2">Acessar</span>
                  </div>
                </div>
              )}

              <div className="col-auto pe-0 ps-1">
                <CartButton />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavDesktop;
