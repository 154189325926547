import FontAwesome from "../../components/Layout/FontAwesome";
import Price from "../../misc/classes/Filters/Price";

const Valores = ({ pedido }) => {
  return (
    <>
      <div className="fsc-1p2 fsc-m-1 mb-4">
        {pedido.id !== null && (
          <>
            <div className="row fw-500 mb-3">
              <div className="col">Subtotal</div>
              <div className="col-auto">
                {Price.numberToCurrency(
                  pedido.total - pedido.delivery_fee + pedido.discount
                )}
              </div>
            </div>

            <div className="row fw-light opacity-7 mb-3">
              <div className="col">Taxa de Entrega</div>
              <div className="col-auto">
                {Price.numberToCurrency(pedido.delivery_fee)}
              </div>
            </div>

            {pedido.discount > 0 && (
              <>
                <div className="row fw-light opacity-7 mb-3">
                  <div className="col">
                    Desconto
                    {pedido.cupom && (
                      <>
                        <div className="d-inline-block fsc-n7 text-success ms-3">
                          <FontAwesome icon='ticket' /> {pedido.cupom}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-auto">
                    {Price.numberToCurrency(pedido.discount)}
                  </div>
                </div>
              </>
            )}

            <div className="row fw-500">
              <div className="col">Total</div>
              <div className="col-auto">
                {Price.numberToCurrency(pedido.total)}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Valores;
