import FontAwesome from "../FontAwesome";
import CSS from "./LoadingShield.module.css";

const LoadingShield = ({ state }) => {
  return (
    <div
      className={
        CSS.loading_shield + " align-items-center " + (state && CSS.hide)
      }
    >
      <div className={CSS.logo}>
        <div className="pt-5">
          <img alt="" src="/img/logo/logo-full-medium.png" />
        </div>
      </div>

      <div className={CSS.loading_text + " pb-5"}>
        <div className="text-center">
          <div className={CSS.loading_icon + " fsc-3 fsc-m-2 mb-2"}>
            <FontAwesome type="solid" icon="utensils" />
          </div>

          <div className="ms-3 fsc-m-n8">Carregando cardápio</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingShield;
