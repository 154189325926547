import { useContext } from "react";
import { useRef } from "react";
import AuthService from "../Auth/Auth";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import UserContext from "../Auth/User/User";
import { useState } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";

const InformacoesPessoais = (props) => {
  const auth = useContext(AuthService);
  const user = useContext(UserContext);
  const nameRef = useRef();
  const documentRef = useRef();
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    formatDocumentNumber();
  }, [auth.user]);

  function onSubmit(e) {
    e.preventDefault();

    resetError();
    resetSuccess();
    setUpdating(true);

    const body = {
      name: nameRef.current.value,
      document: documentRef.current.getAttribute("data-real-value"),
    };

    user.update.personal(body, onSuccess, onError);
  }

  function onSuccess(response) {
    setUpdating(false);
    setSuccess(true);
  }

  function onError() {
    setUpdating(false);
    setError(true);
  }

  function resetError(){
    setError(false);
  }
  
  function resetSuccess(){
    setSuccess(false);    
  }

  function formatDocumentNumber() {
    const value = documentRef.current.value;
    const realValue = value
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/-/g, "");
    let newValue = value;

    if (realValue.length > 2 && realValue.length < 12) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i === 3 || i === 6) {
          newValue += ".";
        }
        if (i === 9) {
          newValue += "-";
        }
        newValue += realValue.charAt(i);
      }
    }

    if (realValue.length > 11) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i > 0 && (i === 2 || i === 5)) {
          newValue += ".";
        }
        if (i > 0 && i === 8) {
          newValue += "/";
        }
        if (i > 0 && i === 12) {
          newValue += "-";
        }

        newValue += realValue.charAt(i);
      }
    }

    documentRef.current.setAttribute("data-real-value", realValue);
    documentRef.current.value = newValue;
  }

  return (
    <>
      <div className="container px-4" style={{ maxWidth: 500 }}>
        <h2 className="my-5">Informações pessoais</h2>

        <form onSubmit={onSubmit}>
          <div className="form-group mt-5">
            <label>Nome completo</label>
            <input
              className="form-control py-2"
              required
              title="Insira seu nome completo"
              ref={nameRef}
              defaultValue={auth.user.name}
            />
          </div>

          <div className="form-group">
            <label>CPF/CNPJ</label>
            <input
              className="form-control py-2"
              required
              inputMode="numeric"
              pattern="[0-9\.-]{14}|[0-9\.-\/]{18}"
              maxLength={18}
              title="Insira o número do seu documento"
              ref={documentRef}
              defaultValue={auth.user.document}
              data-real-value={auth.user.document}
              onChange={formatDocumentNumber}
            />
          </div>

          {error && (
            <div className="alert alert-danger fsc-n8 mb-4">
                <div className="row">
                    <div className="col">
                        Desculpe, mas ocorreu um erro. Tente novamente mais tarde.
                    </div>
                    <div className="col-auto fsc-1p2 cursor-pointer" onClick={resetError}>
                        <FontAwesome icon='times' />
                    </div>
                </div>
            </div>
          )}

          {success && (
            <div className="alert alert-success fsc-n8 mb-4">
                <div className="row">
                    <div className="col">
                        Informações atualizadas com sucesso!
                    </div>
                    <div className="col-auto fsc-1p2 cursor-pointer" onClick={resetSuccess}>
                        <FontAwesome icon='times' />
                    </div>
                </div>
            </div>
          )}

          <div className="row align-self-center justify-content-between">
            <div className="col-auto">
              <NavLink to={"/meus-dados"}>
                <div className="btn btn-outline-secondary fw-bold py-2 link">
                  Voltar
                </div>
              </NavLink>
            </div>
            <div className="col-auto">
              {updating && (
                <div className="text-success">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Carregando...</span>
                  </div>
                </div>
              )}
              {!updating && (
                <button type="submit" className="btn btn-success fw-bold py-2">
                  Atualizar
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InformacoesPessoais;
