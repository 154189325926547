import { useContext } from "react";
import NavCSS from "../../NavCSS.module.css";
import Pagamentos from "../../../../hooks/Configurations/Pagamentos";

const TabsPaymentOption = ({ setTab, payment }) => {
  const pagamentoMethodsContext = useContext(Pagamentos);

  function setWebPay() {
    setTab("DIRECT");
  }

  function setLocalPay() {
    setTab("LOCAL");
  }

  return (
    <>
      <div className="row fsc-1p2 mb-4 text-center">
        {pagamentoMethodsContext.pagamentos.online && (
          <div className="col-auto me-3">
            <div
              onClick={setWebPay}
              className={`${NavCSS.button} ${
                payment.option === "DIRECT" && NavCSS.active
              }`}
            >
              Pagar pelo site
            </div>
          </div>
        )}

        <div className="col-auto">
          <div
            onClick={setLocalPay}
            className={`${NavCSS.button} ${
              payment.option === "LOCAL" && NavCSS.active
            }`}
          >
            Pagar na entrega
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsPaymentOption;
