import { useRef } from "react";
import { useState } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import { useContext } from "react";
import AuthService from "../../Auth";
import { useEffect } from "react";

const GetPassword = ({ tempUser, onSuccess, onCancel }) => {
    const auth = useContext(AuthService);
  const [showPass, setShowPass] = useState(false);
  const passRef = useRef();
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    passRef.current.focus();
  }, []);

  function togglePass(){
    setShowPass(!showPass);
  }

  function onSubmit(e){
    e.preventDefault();

    setLoading(true);

    let user = {...tempUser};
    user.password = passRef.current.value;

    auth.registerUser(user, onRegisterSuccess);
  }

  function onRegisterSuccess(){
    onSuccess();
  }

  return (
    <>
      <form onSubmit={onSubmit} className="p-lg-5 p-4">
        <h3 className="text-center">Entrar</h3>
        <div className="text-center my-3">
          <div>Por fim, defina a senha para acessar seus pedidos.</div>
        </div>

        <div className="form-group my-5">
          <label>Senha</label>

          <div className="row align-items-center">
            <div className="col">
              <input
                required
                type={showPass ? "text" : "password"}
                name="password"
                className="form-control py-3"
                placeholder="Senha"
                ref={passRef}
              />
            </div>
            <div
              className="col-auto text-secondary cursor-pointer"
              onClick={togglePass}
            >
              {showPass ? (
                <FontAwesome icon="eye-slash" />
              ) : (
                <FontAwesome icon="eye" />
              )}
            </div>
          </div>
        </div>

        {loading && (
          <div className="btn btn-success w-100 mt-2 py-3 fw-bold">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}
        {!loading && (
          <button className="btn btn-success w-100 mt-2 py-3 fw-bold">
            Continuar
          </button>
        )}

        <div className="text-center">
          <div
            className="d-inline-block text-success mt-3 cursor-pointer"
            onClick={onCancel}
          >
            Voltar
          </div>
        </div>
      </form>
    </>
  );
};

export default GetPassword;
