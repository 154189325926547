import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";

const Pagamentos = createContext({
  pagamentos: {online: true},
});

export default Pagamentos;

export const PagamentosProvider = ({ children }) => {
  const [pagamentos, setPagamentos] = useState({ online: true });

  useEffect(() => {
    getPagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPagamentos() {
    HttpRequest.get("/configuracoes/pagamentos", onSuccess);
  }

  function onSuccess(response) {
    setPagamentos({ online: response.online });
  }

  const context = {
    pagamentos: pagamentos,
  };

  return <Pagamentos.Provider value={context}>{children}</Pagamentos.Provider>;
};
