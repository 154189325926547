import { useContext } from "react";
import FontAwesome from "../../../Layout/FontAwesome";
import CSS from "./SearchList.module.css";
import ListService from "../ListService";
import { useRef } from "react";

const SearchList = ({ onClear }) => {
  const listService = useContext(ListService);
  const inputRef = useRef();

  function searchIt(e) {
    e.preventDefault();

    const mobile = window.innerWidth < 992;
    const tag = e.target.tagName;
    let value = inputRef.current.value;

    if((tag === 'FORM' && !mobile) || (tag === 'INPUT' && mobile) ){
        return;
    }

    listService.search(value);
  }

  function clearSearch() {
    inputRef.current.value = null;
    listService.search("");
    if (onClear) onClear();
  }

  return (
    <>
      <div className={"search-input border rounded p-3 " + CSS.search_list}>
        <form onSubmit={searchIt}>
            <div className="row align-items-center">
            <div className="col-auto px-4 text-success fsc-1p5 opacity-5">
                <FontAwesome icon="magnifying-glass" type="solid" />
            </div>
            <div className="col ps-0">
                <input
                className="styless form-control fsc-1p2 px-0"
                type="text"
                placeholder="Buscar no cardápio"
                ref={inputRef}
                inputMode="search"
                onChange={searchIt}
                />
            </div>
            <div
                className="col-auto px-4 fsc-1p5 opacity-5 cursor-pointer"
                onClick={clearSearch}
            >
                <FontAwesome icon="times" type="solid" />
            </div>
            </div>
        </form>
      </div>
    </>
  );
};

export default SearchList;
