import { NavLink } from "react-router-dom";
import FontAwesome from "../FontAwesome";
import CSS from "./NavItem.module.css";

const NavItemMobile = (props) => {
  const element = (
    <>
      <div>
        <FontAwesome icon={props.icon} />
      </div>
      <div className="fsc-n6" style={{ letterSpacing: "1px" }}>
        {props.children}
      </div>
    </>
  );

  return (
    <>
      {props.to !== null && (
        <NavLink
          to={props.to}
          end={props.exact}
          className={({ isActive }) =>
            CSS.nav_item + " " + (isActive ? CSS.active : null)
          }
        >
          {element}
        </NavLink>
      )}

      {props.to === null &&  element}
    </>
  );
};

export default NavItemMobile;
