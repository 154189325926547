import { useContext } from "react";
import CartList from "./CartList";
import Price from "../../misc/classes/Filters/Price";

const PedidoTotais = ({total}) => {
    const cart = useContext(CartList);

  return (
    <div className="fw-light opacity-8 mt-5 fsc-m-n9">
      <div className="row">
        <div className="col">Subtotal</div>
        <div className="col-auto">{cart.total.formatted}</div>
      </div>
      <div className="row mt-3">
        <div className="col">Taxa de entrega</div>
        <div className="col-auto">{cart.delivery.formatted}</div>
      </div>
      {cart.cupom !== null && (<>
        <div className="row mt-3">
            <div className="col">Desconto</div>
            <div className="col-auto">{Price.numberToCurrency(cart.getDiscount())}</div>
        </div>
      </>)}
      <div className={"row mt-3 fw-500 d-none fsc-1p2 " + (total && 'd-lg-flex')}>
        <div className="col">Total</div>
        <div className="col-auto">{ Price.numberToCurrency((cart.total.number + cart.delivery.number) - cart.getDiscount()) }</div>
      </div>
    </div>
  );
};

export default PedidoTotais;
