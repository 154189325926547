import { useContext } from "react";
import CartList from "../CartList";
import Price from "../../../misc/classes/Filters/Price";
import { useNavigate } from "react-router-dom";

const PedidoAction = (props) => {
    const cart = useContext(CartList);
    const navigate = useNavigate();

    function goToFinalizar(){
        navigate('/finalizar-pagamento');
    }

   return (
       <>
           <div className="row fw-500">
                <div className="col fsc-1p2 fsc-m-1">Total</div>
                <div className="col-auto fsc-m-n9">
                { Price.numberToCurrency((cart.total.number + cart.delivery.number) - cart.getDiscount()) }
                </div>
           </div>

           <div className="btn btn-success fw-bold w-100 py-3 fsc-m-n9 mt-3" data-bs-dismiss='modal' onClick={goToFinalizar}>
                Escolher forma de pagamento
           </div>
       </>
   )
}

export default PedidoAction;