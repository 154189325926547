import Price from "../../misc/classes/Filters/Price";

const Items = ({ pedido }) => {
  return (
    <>
      <div className="fsc-1p2 fsc-m-1 mb-4">
        {pedido.id !== null && pedido.items.map((item, index) => {
          return (
            <div className="mb-4" key={index}>
              <div className="row fw-500">
                <div className="col">
                  {item.quantity}x {item.name}
                </div>
                <div className="col-auto">{Price.numberToCurrency(item.quantity * item.price)}</div>
              </div>
              <div className="opacity-7 fsc-n8">{item.ingredients}</div>
              
                
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Items;
