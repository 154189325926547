import { forwardRef } from 'react';
import CSS from './CartAlerts.module.css';
import { useImperativeHandle } from 'react';
import { useState } from 'react';

const CartAlerts = forwardRef((props, ref) => {
    const [deleteState, setDelete] = useState(false);
    const [addState, setAdd] = useState(false);
    const [editState, setEdit] = useState(false);

    useImperativeHandle(ref, ()=>({
        showAdd, showDelete, showEdit
    }));

    function showDelete(){
        setDelete(true);

        setTimeout(()=>{
            setDelete(false);
        }, 3000);
    }
    
    function showAdd(){
        setAdd(true);

        setTimeout(()=>{
            setAdd(false);
        }, 3000);
    }
    
    function showEdit(){
        setEdit(true);

        setTimeout(()=>{
            setEdit(false);
        }, 3000);
    }

    function hideAdd(){
        setAdd(false);
    }
    function hideEdit(){
        setEdit(false);
    }
    function hideDelete(){
        setDelete(false);
    }

   return (
       <>
           <div className={CSS.cart_alert + ' p-4 rounded-bottom text-center ' + CSS.alert_success + ' ' + (addState && CSS.alert_show)} onClick={hideAdd}>
                Produto adicionado ao carrinho com sucesso.
           </div>
           
           <div className={CSS.cart_alert + ' p-4 rounded-bottom text-center ' + CSS.alert_delete + ' ' + (deleteState && CSS.alert_show)} onClick={hideDelete}>
                Produto removido do carrinho com sucesso.
           </div>
           
           <div className={CSS.cart_alert + ' p-4 rounded-bottom text-center ' + CSS.alert_success + ' ' + (editState && CSS.alert_show)} onClick={hideEdit}>
                Produto atualizado no carrinho com sucesso.
           </div>
       </>
   )
});

export default CartAlerts;