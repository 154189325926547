import { useContext } from "react";
import CSS from "./CategoriasNav.module.css";
import NavService from "./NavService";

const NavItem = ({ category, index }) => {
  const navService = useContext(NavService);

  function goTo() {
    navService.navClick();
    navService.scrollNav(index);
    navService.scrollPage(index);
    navService.setActive(index);
  }

  return (
    <>
      <div
        id={"cardapio-nav-" + index}
        className={
          CSS.nav_link + " " + (navService.active === index && "active")
        }
        onClick={goTo}
      >
        {category.name}
      </div>
    </>
  );
};

export default NavItem;
