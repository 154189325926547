import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Cardapio from "../Paginas/Cardapio/Cardapio";
import FinalizarPagamento from "../Paginas/FinalizarPagamento/FinalizarPagamento";
import Pedidos from "../Paginas/Pedidos/Pedidos";
import TermosDeUso from "../Paginas/Termos/TermosDeUso";
import Pedido from "../Paginas/Pedido/Pedido";
import MeusDados from "../Paginas/MeusDados/MeusDados";
import InformacoesPessoais from "../Paginas/MeusDados/InformacoesPessoais";
import DadosContato from "../Paginas/MeusDados/DadosContato";
import Credenciais from "../Paginas/MeusDados/Credenciais";
import GuardedRoute from "./GuardRoute";
import RecuperarSenha from "../Paginas/RecuperarSenha/RecuperarSenha";

const RoutesList = () => {
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    } else {
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" index={true} element={<Cardapio />} />

        <Route path="/pedidos" element={<Pedidos />} />

        <Route path="/pedidos/:orderId" element={<Pedido />} />

        <Route path="/finalizar-pagamento" element={<FinalizarPagamento />} />

        <Route path='/meus-dados' element={<GuardedRoute element={<MeusDados />} />} />

        <Route path='/meus-dados/informacoes-pessoais' element={<GuardedRoute element={<InformacoesPessoais />} />} />

        <Route path='/meus-dados/dados-contato' element={<GuardedRoute element={<DadosContato />} />} />

        <Route path='/meus-dados/credenciais' element={<GuardedRoute element={<Credenciais />} />} />

        <Route path='/termos-de-uso' element={<TermosDeUso />} />

        <Route path='/recuperar-senha' element={<RecuperarSenha />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default RoutesList;
