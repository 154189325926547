import { useState } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import CSS from "./ProdutosSubgroup.module.css";
import { useEffect } from "react";

const ProdutosIngredients = ({
  product,
  subgroup,
  groupIndex,
  ingredient,
  index,
  groupQtd,
  checked,
  onCheck,
  onAdd,
  onRemove,
}) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    proccessDefaultValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function proccessDefaultValue() {
    if (
      !(product.choosed_ingredients && product.choosed_ingredients[groupIndex])
    ) {
      return;
    }

    const choosed = product.choosed_ingredients[groupIndex];

    const count = choosed.reduce(function (acc, curr) {
      if (curr === index) {
        return acc + 1;
      }
      return acc;
    }, 0);

    setQuantity(count);
  }

  function add() {
    onAdd(index, successAdd);
  }

  function successAdd() {
    setQuantity(quantity + 1);
  }

  function remove() {
    onRemove(index, successRemove);
  }

  function successRemove() {
    setQuantity(quantity - 1);
  }

  function groupIsMaxed() {
    const qtdLimit = parseInt(subgroup.quantity);

    if (typeof qtdLimit !== "number" || qtdLimit === 0) {
      return false;
    }

    return groupQtd === qtdLimit;
  }

  function checkedIt() {
    onCheck(index);
  }

  return (
    <>
      <div className={"px-4 py-4 border-bottom " + CSS.subgroup_option}>
        <div className="row align-items-center">
          <div className="col">
            <div className="fw-light">{ingredient.name}</div>

            {ingredient.description && (
              <div className="opacity-7 fw-light fsc-n8 mt-2">
                {ingredient.description}
              </div>
            )}

            {ingredient.price !== null && ingredient.price !== "" && (
              <div className="opacity-7 fw-light fsc-n9 mt-2">
                + R$ {ingredient.price}
              </div>
            )}
          </div>
          {ingredient.photo && (
            <div className="col-auto">
              <img
                src={
                  process.env.REACT_APP_IMG_URL +
                  "/ingredientes/" +
                  ingredient.photo
                }
                alt=""
                className={CSS.subgroup_option_photo}
              />
            </div>
          )}
          <div className="col-auto">
            {subgroup.required && subgroup.required === "1" && (
              <>
                <div
                  className={
                    CSS.subgroup_checkbox_radio +
                    " " +
                    (checked === index && "checked")
                  }
                  onClick={checkedIt}
                >
                  <div></div>
                </div>
              </>
            )}

            {(!subgroup.required ||
              (subgroup.required && subgroup.required === "0")) && (
              <>
                <div className="row fsc-1p2 h-100 align-items-center">
                  {quantity > 0 && (
                    <>
                      <div
                        className={"col-auto text-success cursor-pointer"}
                        onClick={remove}
                      >
                        <FontAwesome icon="minus" />
                      </div>
                      <div className="col-auto fw-bold">{quantity}</div>
                    </>
                  )}

                  <div
                    className={
                      "col-auto text-success cursor-pointer " +
                      (groupIsMaxed() && CSS.disable_action)
                    }
                    onClick={add}
                  >
                    <FontAwesome icon="plus" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProdutosIngredients;
