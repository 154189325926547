import { NavLink, useNavigate } from "react-router-dom";
import FontAwesome from "../../components/Layout/FontAwesome";
import { useContext } from "react";
import AuthService from "../Auth/Auth";

const MeusDados = (props) => {
    const auth = useContext(AuthService);
    const navigate = useNavigate();

    function signOut(){
        auth.signOutUser();
        navigate('/');
    }

  return (
    <>
      <div className="container px-4" style={{ maxWidth: 500 }}>
        <h2 className="my-5">Meus Dados</h2>

        <NavLink
          to={"/meus-dados/informacoes-pessoais"}
          className={"link text-body"}
        >
          <div className="row align-items-center py-4 border-bottom fsc-1p2 fsc-m-1">
            <div className="col">
              <div className="fw-500 mb-1 text-success">
                Informações pessoais
              </div>
              <div className="text-secondary">Nome completo e CPF</div>
            </div>
            <div className="col-auto text-success fsc-n8">
              <FontAwesome icon="chevron-right" />
            </div>
          </div>
        </NavLink>

        <NavLink to={"/meus-dados/dados-contato"} className={"link text-body"}>
          <div className="row align-items-center py-4 border-bottom fsc-1p2 fsc-m-1">
            <div className="col">
              <div className="fw-500 mb-1 text-success">Dados de contato</div>
              <div className="text-secondary">E-mail e telefone de contato</div>
            </div>
            <div className="col-auto text-success fsc-n8">
              <FontAwesome icon="chevron-right" />
            </div>
          </div>
        </NavLink>

        <NavLink to={"/meus-dados/credenciais"} className={"link text-body"}>
          <div className="row align-items-center py-4 border-bottom fsc-1p2 fsc-m-1">
            <div className="col">
              <div className="fw-500 mb-1 text-success">Credenciais</div>
              <div className="text-secondary">
                Meios de acesso a minha conta
              </div>
            </div>
            <div className="col-auto text-success fsc-n8">
              <FontAwesome icon="chevron-right" />
            </div>
          </div>
        </NavLink>

        <div className="py-4 border-bottom cursor-pointer" onClick={signOut}>
          <div className="fw-500 mb-1 text-success">Sair</div>
          <div className="text-secondary">Desconectar da sua conta</div>
        </div>
      </div>
    </>
  );
};

export default MeusDados;
