import { useContext, useRef, useState } from "react";
import NavCSS from "../NavCSS.module.css";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import CartList from "../../Cart/CartList";
import ModalEndereco from "./ModalEndereco";
import AuthService from "../../Auth/Auth";
import { useEffect } from "react";

const Entrega = forwardRef((props, ref) => {
  const cart = useContext(CartList);
  const [delivery, setDelivery] = useState({ option: 0, address: {} });
  const modalRef = useRef();
  const [notDefined, setNotDefined] = useState(false);
  const componentRef = useRef();
  const auth = useContext(AuthService);

  useImperativeHandle(ref, () => ({
    getDelivery,
  }));

  useEffect(()=>{
    if(auth.lastAddress !== null){
        onSuccessUpdate(auth.lastAddress);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.lastAddress]);

  function getDelivery() {
    if (delivery.option !== 1 && (!delivery.address.numero || delivery.address.localidade !== "Avaré")) {
      setNotDefined(true);
      componentRef.current.scrollIntoView(true);
      return false;
    }

    return { ...delivery };
  }

  function openForm() {
    setNotDefined(false);
    modalRef.current.open();
  }

  function setEntrega() {
    setNotDefined(false);
    const newDelivery = { ...delivery };
    newDelivery.option = 0;
    setDelivery(newDelivery);
    cart.changeDelivery(0);
  }

  function setRetirada() {
    setNotDefined(false);
    const newDelivery = { ...delivery };
    newDelivery.option = 1;
    setDelivery(newDelivery);
    cart.changeDelivery(1);
  }

  function onSuccessUpdate(address) {
    const newDelivery = { ...delivery };
    newDelivery.option = 0;
    newDelivery.address = address;

    setDelivery(newDelivery);
    cart.changeDelivery(0);
  }

  function hideNotDefined(){
    setNotDefined(false)
  }

  return (
    <>
      <div className="fsc-m-n8 pt-5" ref={componentRef}>
        <h3 className="mb-3 fw-500">Entrega</h3>

        {notDefined && (
            <div className="alert alert-danger fsc-n9 py-1">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <FontAwesome icon='exclamation-circle' />
                    </div>
                    <div className="col">
                        Por favor, escolha o método de entrega.
                    </div>
                    <div className="col-auto fsc-1p5 cursor-pointer" onClick={hideNotDefined}>
                        <FontAwesome icon='times' />
                    </div>
                </div>
            </div>
        )}

        <div className="row fsc-1p2 mb-4 text-center">
          <div className="col-auto">
            <div
              onClick={setEntrega}
              className={
                NavCSS.button + " " + (delivery.option === 0 && NavCSS.active)
              }
            >
              Entrega
            </div>
          </div>
          <div className="col-auto px-4">
            <div
              onClick={setRetirada}
              className={
                NavCSS.button + " " + (delivery.option === 1 && NavCSS.active)
              }
            >
              Retirada
            </div>
          </div>
        </div>

        {delivery.option === 0 && (
          <>
            <div className="row align-items-center py-2">
              <div className="col-auto text-success fsc-3">
                <FontAwesome icon="motorcycle" />
              </div>
              <div className="col fsc-1p2">
                <div className="row">
                  <div className="col-lg col-12">
                    {!delivery.address.logradouro && (
                      <div
                        className="opacity-7 cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#entrega-form"
                      >
                        Selecione o endereço
                      </div>
                    )}

                    {delivery.address.logradouro && (
                      <>
                        <div>
                          {delivery.address.logradouro},{" "}
                          {delivery.address.numero}
                        </div>
                        <div className="opacity-7">
                          {delivery.address.localidade}/{delivery.address.uf}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="col-lg-auto col-12 text-end text-success cursor-pointer"
                    onClick={openForm}
                  >
                    Trocar
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {delivery.option === 1 && (
          <div className="row align-items-center py-2">
            <div className="col-auto text-success fsc-3">
              <img
                alt="logo"
                src="img/logo/logo-full-small.png"
                style={{ width: "80px" }}
              />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-lg col-12 fsc-1p2">
                  <div className="fw-500">Avenida Major Rangel, 1214</div>
                  <div className="opacity-7">Avaré/SP</div>
                </div>
                <div className="col-lg-auto col-12 text-end text-success cursor-pointer">
                  <a
                    href="https://www.google.com/maps/place/Av.+Maj.+Rangel,+1214+-+Centro,+Avar%C3%A9+-+SP,+18705-040/@-23.0964082,-48.9242317,20z/data=!4m6!3m5!1s0x94c12688c013c6ff:0x6d310fde638a0eee!8m2!3d-23.0964163!4d-48.9241229!16s%2Fg%2F11c4yzc89y?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                    className="link text-success"
                  >
                    Ver mapa
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="px-3 py-3 border rounded mt-4 border-success">
          <div className="text-success opacity-7">
            {delivery.option === 0 ? "Padrão" : "Retirar no restaurante"}
          </div>

          <div className="fw-500 fsc-1p2 my-2">
            Hoje, {delivery.option === 0 ? "49-59" : "15-30"} min
          </div>

          <div className="text-success fsc-1p2 fw-bold">
            {cart.delivery.number === 0 ? "Grátis" : cart.delivery.formatted}
          </div>
        </div>
      </div>

      <ModalEndereco ref={modalRef} onSuccess={onSuccessUpdate} defaultAddress={auth.lastAddress} />
    </>
  );
});

export default Entrega;
