import { useState } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import CSS from "./ModalAction.module.css";
import { useEffect } from "react";
import { useContext } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import Price from "../../../misc/classes/Filters/Price";
import CartList from "../../Cart/CartList";
import Horarios from "../../../hooks/Configurations/Horarios";

const ModalAction = forwardRef(({ product, listOptions, edit, defaultValue }, ref) => {
  const [quantity, setQuantity] = useState(1);
  const [currentPrice, setCurrentPrice] = useState(0);
  const cart = useContext(CartList);
  const horarios = useContext(Horarios);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  useEffect(()=>{
    if(defaultValue){
        setQuantity(defaultValue.quantity);
        setCurrentPrice(defaultValue.final_price);
    }
  }, [defaultValue]);

  function reset(qtd = 1){
    setQuantity(qtd);
  }

  useEffect(() => {
    getOptionsPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOptions, quantity]);

  function getOptionsPrices() {
    let price = 0;

    listOptions.forEach((optionsList, index) => {
      const group = product.ingredients[index];

      optionsList.forEach((e) => {
        const option = group.ingredients[e];

        const optionPrice = Price.currencyToNumber(option.price);

        price = price + optionPrice;
      });
    });

    setCurrentPrice((price + product.price) * quantity);
  }

  function add() {
    setQuantity(quantity + 1);
  }

  function remove() {
    if (quantity === 1) return;

    setQuantity(quantity - 1);
  }

  function addToCard(){
    if(!checkHorarios() || !verifyIfRequired()){
        return;
    };

    const cartProduct = {
        ...product,
        observations: document.getElementById('costumer-observations').value,
        quantity: quantity,
        final_price: currentPrice,
        choosed_ingredients: listOptions
    };

    cart.add(cartProduct);
  }

  function updateOnCart(){
    if(!checkHorarios() || !verifyIfRequired()){
        return;
    };

    let cartProduct = {...product};

    cartProduct.observations = document.getElementById('costumer-observations').value;
    cartProduct.quantity = quantity;
    cartProduct.final_price = currentPrice;
    cartProduct.choosed_ingredients = listOptions;

    cart.edit(edit.index, cartProduct);
  }

  function verifyIfRequired(){
    const list = product.ingredients;

    let valid = true;

    for (let index = 0; index < list.length; index++) {
        const group = list[index];

        if(!(group.required && group.required === '1')){
            continue;
        };

        if(listOptions[index].length === 0){
            valid = false;

            const groupElement = document.getElementById('group-ingredients-'+index);

            groupElement.scrollIntoView();
            groupElement.classList.add('required');

            setTimeout(()=>{
                groupElement.classList.remove('required');
            }, 5000);

            break;
        }
    }

    return valid;
  }

  function checkHorarios(){
    if(!horarios.open()){
        horarios.warning();    
        return false;
    }

    return true;
  }

  return (
    <>
      <div
        className={
          "row align-items-stretch mx-0 " + CSS.action
        }
      >
        <div className="col-auto pe-0 px-lg-3">
          <div
            className={
              "row p-2 border rounded fsc-1p2 fsc-m-1 h-100 align-items-center mx-0 " +
              CSS.border_2px
            }
          >
            <div
              className={
                "col-auto text-success cursor-pointer ps-0 px-lg-3 " +
                (quantity === 1 && CSS.disable_action)
              }
              onClick={remove}
            >
              <FontAwesome icon="minus" />
            </div>
            <div className="col-auto fw-bold">{quantity}</div>
            <div className="col-auto text-success cursor-pointer pe-0 px-lg-3" onClick={add}>
              <FontAwesome icon="plus" />
            </div>
          </div>
        </div>
        <div className="col-auto">
          <button className="btn btn-success h-100 py-2 px-4" onClick={edit.edit ? updateOnCart : addToCard}>
            <div className="row align-items-center fsc-1p2 fsc-m-1 ">
              <div className="col-auto fsc-n9">{edit.edit ? 'Atualizar' : 'Adicionar'}</div>
              <div className="col-auto">{Price.numberToCurrency(currentPrice)}</div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
});

export default ModalAction;
