import FontAwesome from "../../../components/Layout/FontAwesome";

const ProcessingCard = (props) => {
   return (
       <>
       <div className="text-center p-4 p-lg-5">
         <div className="fsc-3 text-danger">
           <FontAwesome icon="magnifying-glass-dollar" />
         </div>
 
         <h4 className="mt-4 mb-5 fw-light">Pagamento em Análise</h4>
 
         <div className="rounded p-3 bg-light border text-center">
           <div>A compra está em análise pera operadora do cartão.</div>
           <div className="fw-light fsc-n9 mt-2">
             Isto pode levar alguns minutos, por favor clique aqui para visualizar o seu pedido e aguardar o pagamento.
           </div>
         </div>
 
         <div
           className="btn btn-success mt-3 w-100 fw-bold py-3"
           data-bs-dismiss="modal"
         >
           Visualizar Pedido
         </div>
       </div>
           
       </>
   )
}

export default ProcessingCard;