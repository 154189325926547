import { useRef } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import Modal from "../../../components/Layout/Modal/Modal";
import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";

const ModalEndereco = forwardRef(({ onSuccess, defaultAddress }, ref) => {
  const modalRef = useRef();
  const cepRef = useRef();
  const logradouroRef = useRef();
  const numeroRef = useRef();
  const complementoRef = useRef();
  const bairroRef = useRef();
  const cidadeRef = useRef();
  const ufRef = useRef();
  const [valid, setValid] = useState(true);
  const [temp, setTemp] = useState({});

  useImperativeHandle(ref, () => ({
    open
  }));

  useEffect(()=>{
    if(defaultAddress !== null){
        autoFillDefault(defaultAddress);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddress]);

  function open(){
    modalRef.current.open();
  };

  function fetchCEPIfValid(){
    let cep = cepRef.current.value;
    cep = cep.replace("-", "");

    if(cep.length === 8){
        fetchCEP();
    }
  }

  function fetchCEP() {
    let cep = cepRef.current.value;

    cep = cep.replace("-", "");

    fetch("https://viacep.com.br/ws/" + cep + "/json/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        autoFillAddress(response);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") console.log(error);
      });
  }

  function autoFillAddress(address) {
    if (!addressValid(address.localidade)) {
      setValid(false);
      return;
    }

    logradouroRef.current.value = address.logradouro;
    bairroRef.current.value = address.bairro;
    cidadeRef.current.value = address.localidade;
    ufRef.current.value = address.uf;

    setTemp(address);
    numeroRef.current.focus();
  }

  function addressValid(cidade) {
    return cidade === "Avaré";
  }

  function resetValidity() {
    setValid(true);
  }

  function submitAddress(e) {
    e.preventDefault();

    if (!addressValid(temp.localidade)) {
      setValid(false);
      setTemp({});
      return;
    }

    const address = { ...temp };
    address.numero = numeroRef.current.value;
    address.complemento = complementoRef.current.value;

    onSuccess(address);

    modalRef.current.close();
  }

  function autoFillDefault(address){
    cepRef.current.value = address.cep;
    logradouroRef.current.value = address.logradouro;
    numeroRef.current.value = address.numero;
    bairroRef.current.value = address.bairro;
    complementoRef.current.value = address.complemento;
    cidadeRef.current.value = address.localidade;
    ufRef.current.value = address.uf;
  }

  return (
    <>
      <Modal
        id="entrega-form"
        ref={modalRef}
        isStatic={true}
        title={valid ? "Endereço" : ""}
      >
        {valid && (
          <form onSubmit={submitAddress} className="px-lg-5 px-3 pb-5 pt-3">
            <div className="form-group">
              <label>CEP</label>
              <input className="form-control" ref={cepRef} required onChange={fetchCEPIfValid} />
              <div className="text-end">
                <div
                  className="d-inline-block text-success mt-2 cursor-pointer"
                  onClick={fetchCEP}
                >
                  Buscar CEP
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Rua</label>
                  <input
                    className="form-control"
                    ref={logradouroRef}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="form-group">
                  <label>Nº</label>
                  <input className="form-control" ref={numeroRef} required />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Complemento</label>
              <input className="form-control" ref={complementoRef} />
            </div>

            <div className="form-group">
              <label>Bairro</label>
              <input
                className="form-control"
                ref={bairroRef}
                readOnly
                disabled
              />
            </div>

            <div className="row">
              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Cidade</label>
                  <input
                    className="form-control"
                    ref={cidadeRef}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <label>UF</label>
                  <input
                    className="form-control"
                    ref={ufRef}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col text-end">
                  <button className="btn btn-success fw-bold w-100 py-3">
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}

        {!valid && (
          <div className="px-5 pb-5 pt-3 text-center">
            <div className="fsc-4 text-success">
              <FontAwesome icon="map-location" />
            </div>

            <div className="fsc-1p2 fw-light mt-4">
              Infelizmente ainda não entregamos no endereço selecionado.
            </div>

            <div className="mt-5">Deseja selecionar outro endereço?</div>

            <div
              className="btn btn-success w-100 mt-4 fsc-1p2 py-3"
              onClick={resetValidity}
            >
              Sim
            </div>
          </div>
        )}
      </Modal>
    </>
  );
});

export default ModalEndereco;
