import { useContext, useState } from "react";
import PedidoAberto from "../Cart/PedidoAberto/PedidoAberto";
import PedidoTotais from "../Cart/PedidoTotais";
import Entrega from "./Entrega/Entrega";
import Pagamento from "./Pagamento/Pagamento";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import CartList from "../Cart/CartList";
import { useRef } from "react";
import Modal from "../../components/Layout/Modal/Modal";
import { useNavigate } from "react-router-dom";
import ShieldProcessing from "./ShieldProcessing/ShieldProcessing";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";
import PagarPix from "./PagarPix/PagarPix";
import Cupom from "./Cupom/Cupom";
import Horarios from "../../hooks/Configurations/Horarios";
import NoProductsCart from "./ModalErrors/NoProductsCart";
import CardDeclined from "./ModalErrors/CardDeclined";
import Error from "./ModalErrors/Error";
import { useEffect } from "react";
import ProcessingCard from "./ModalErrors/ProcessingCard";

const FinalizarPagamento = (props) => {
  const navigate = useNavigate();
  const cart = useContext(CartList);
  const horarios = useContext(Horarios);

  const pagRef = useRef();
  const deliveryRef = useRef();
  const userRef = useRef();
  const errorRef = useRef();
  const cupomRef = useRef();

  const modalNoProductsRef = useRef();
  const modalProccessingRef = useRef();
  const modalDeclinedCardRef = useRef();
  const modalProcessingCardRef = useRef();
  const pixModal = useRef();

  const [error, setError] = useState();

  useEffect(() => {
    const products = [...cart.list];

    if (products.length === 0) {
      modalNoProductsRef.current.open();
    }
  }, [cart.list]);

  function finalizarPedido() {
    if (!horarios.open()) {
      horarios.warning();
      return;
    }

    const products = [...cart.list];
    if (products.length === 0) {
      modalNoProductsRef.current.open();
      return;
    }

    const delivery = { ...cart.delivery, ...deliveryRef.current.getDelivery() };
    if (!delivery.address) return;

    const payment = pagRef.current.getPayment();
    if (!payment) return;

    const user = userRef.current.getUser(finalizarPedido);
    if (!user) return;

    const cupom = cupomRef.current.getCupom();

    modalProccessingRef.current.open();

    HttpRequest.post(
      "/pedidos",
      JSON.stringify({ products, delivery, payment, cupom, jwt: user.jwt }),
      onPedidoCriado,
      onError
    );
  }

  function onPedidoCriado(response) {
    if (response.status === 200) {
      closeProcessingModal().then(() => {
        if (response.payment.method === "pix") {
          pixModal.current.open(response);
          return;
        }

        cart.reset();
        navigate("/pedidos/" + response.order);
        return;
      });
    }
  }

  function onError(response) {
    setError(response.error);
    closeProcessingModal().then(() => {
      triggerErrorModals(response);
    });
  }

  function triggerErrorModals(response) {
    if (response.payment && response.payment.type === "DIRECT") {
      if (response.payment.method === "credit") {
        switch (response.payment.status) {
          case "rejected":
            pagRef.current.clearInformations();
            modalDeclinedCardRef.current.open();
            return true;
          case "in_process":
            pagRef.current.clearInformations();
            modalDeclinedCardRef.current.open();
            return true;
          default:
            break;
        }
      }

      if (
        response.payment.method === "credit" &&
        response.payment.status === "rejected"
      ) {
        pagRef.current.clearInformations();
        modalDeclinedCardRef.current.open();
        return true;
      }
    }

    if (response.error) {
      switch (response.error.code) {
        case "700":
          horarios.warning();
          return true;

        default:
          break;
      }
    }

    errorRef.current.open();

    return false;
  }

  async function closeProcessingModal() {
    return new Promise((resolve) => {
      setTimeout(() => {
        modalProccessingRef.current.close();

        setTimeout(() => {
          resolve();
        }, 100);
      }, 500);
    });
  }

  return (
    <>
      <div className="container px-4 px-lg-2 mt-lg-5 mt-4">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-12">
            <h1>Finalizar pedido</h1>

            <Entrega ref={deliveryRef} />

            <hr className="mt-5 mb-0" />

            <Pagamento ref={pagRef} />

            <hr className="mt-5 mb-0" />

            <PersonalInfo ref={userRef} />

            <div
              className="w-100 mt-5 mb-5 btn btn-success py-3 fw-bold"
              onClick={finalizarPedido}
            >
              Finalizar Pedido
            </div>
          </div>
          <div className="col-lg-5 col-12 mt-5 mt-lg-0 pb-5 pb-lg-0">
            <div className="shadow rounded p-lg-5 p-4">
              <PedidoAberto />
              <Cupom ref={cupomRef} />
              <PedidoTotais total={true} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="no-products-in-cart"
        className="modal-headless"
        ref={modalNoProductsRef}
        isStatic={true}
      >
        <NoProductsCart />
      </Modal>

      <Modal
        id="processing-payment"
        className="modal-headless"
        ref={modalProccessingRef}
        isStatic={true}
      >
        <ShieldProcessing />
      </Modal>

      <Modal id="error-order" ref={errorRef} isStatic={true}>
        <Error error={error} />
      </Modal>

      <Modal
        id="card-declined"
        className="modal-headless"
        ref={modalDeclinedCardRef}
      >
        <CardDeclined />
      </Modal>

      <Modal
        id="processing-card"
        className="modal-headless"
        ref={modalProcessingCardRef}
      >
        <ProcessingCard />
      </Modal>

      <PagarPix ref={pixModal} />
    </>
  );
};

export default FinalizarPagamento;
