import { useContext } from "react";
import UserContext from "../../../Paginas/Auth/User/User";
import { useNavigate } from "react-router-dom";
import NavMobile from "./NavMobile";
import AuthService from "../../../Paginas/Auth/Auth";
import NavDesktop from "./NavDesktop";

const Nav = () => {
  const user = useContext(UserContext);
  const auth = useContext(AuthService);

  const navigate = useNavigate();

  function signIn(){
    auth.onSigin(()=>navigate('/pedidos'));
    user.open();
  }

  return (
    <>
      <section>
        <NavDesktop signIn={signIn} />
        <NavMobile signIn={signIn} />
      </section>
    </>
  );
};

export default Nav;
