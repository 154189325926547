
const localPaymentMethods = [
    {
      name: "Dinheiro",
      image: "/img/pagamento/dinheiro.png",
      value: "dinheiro",
    },
    {
      name: "Mastercard - Débito",
      image: "/img/pagamento/mastercard.png",
      value: "master_debito",
    },
    {
      name: "Visa - Débito",
      image: "/img/pagamento/visa.png",
      value: "visa_debito",
    },
    {
      name: "Elo - Débito",
      image: "/img/pagamento/elo.png",
      value: "elo_debito",
    },
    {
      name: "Visa - Crédito",
      image: "/img/pagamento/visa.png",
      value: "visa_credito",
    },
    {
      name: "Mastercard - Crédito",
      image: "/img/pagamento/mastercard.png",
      value: "mastercard_credito",
    },
    {
      name: "Elo - Crédito",
      image: "/img/pagamento/elo.png",
      value: "elo_credito",
    },
    {
      name: "Amex - Crédito",
      image: "/img/pagamento/amex.png",
      value: "amex_credito",
    },
    {
      name: "Ticket - Vale-Refeição",
      image: "/img/pagamento/ticket.png",
      value: "ticket",
    },
  ];

const LocalPayments = ({payment, selectLocalPay}) => {
   return (
       <>
           <div className="row">
            {localPaymentMethods.map((paymentOption, index) => {
              return (
                <div className="col-lg-6 col-12 py-2" key={index}>
                  <div
                    className={
                      "row mx-0 border rounded cursor-pointer py-3 card_shadow align-items-center " +
                      (payment.method === paymentOption.value && "border-success")
                    }
                    data-value={paymentOption.value}
                    onClick={selectLocalPay}
                  >
                    <div className="col-auto">
                      <img
                        alt=""
                        src={paymentOption.image}
                        style={{ height: "15px" }}
                      />
                    </div>
                    <div className="col">{paymentOption.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
       </>
   )
}

export default LocalPayments;