import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../Paginas/Auth/Auth";

const GuardedRoute = ({ element, condition, redirectTo, ...props }) => {
  const auth = useContext(AuthService);

  return (
    <>
      {auth.init && (
        <>
          {auth.logged === true ? (
            element
          ) : (
            <Navigate to={redirectTo ? redirectTo : "/"} replace={true} />
          )}
        </>
      )}

      {!auth.init && (
        <div className="my-5 text-center text-success">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
        </div>
      )}
    </>
  );
};

export default GuardedRoute;
