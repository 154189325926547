import { NavLink } from "react-router-dom";
import FontAwesome from "../../../components/Layout/FontAwesome";

const NoProductsCart = (props) => {
  return (
    <>
      <div className="p-5 text-center">
        <div className="fsc-3 text-success">
          <FontAwesome icon="shopping-cart" />
        </div>

        <div className="mt-4 mb-5 fw-light">
          Não há itens
          <br />
          em seu carrinho.
        </div>

        <NavLink to="/">
          <div
            className="btn btn-success text-center w-100 py-3 fw-bold"
            data-bs-dismiss="modal"
          >
            Ver cardápio
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default NoProductsCart;
