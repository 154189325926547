import { useState } from "react";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";
import CSS from './SearchCardapioMobile.module.css';
import FontAwesome from "../../../components/Layout/FontAwesome";

const SearchCardapioMobile = (props) => {
    const [show, setShow] = useState(false);

    function onClear(){
        setShow(false);
    }

    function showIt(){
        setShow(true);
    }

   return (
       <>
           <div className={'d-lg-none ' + CSS.search + ' fsc-n8 ' + (show && CSS.show)}>
                <SearchList onClear={onClear} />
           </div>
           <div className={CSS.search_button + ' d-lg-none'} onClick={showIt}>
                <FontAwesome icon='magnifying-glass' />
           </div>
       </>
   )
}

export default SearchCardapioMobile;