import CSS from "../../components/Layout/Modal/Modal.module.css";
import Cupom from "../FinalizarPagamento/Cupom/Cupom";
import CSS2 from "./Cart.module.css";
import PedidoAberto from "./PedidoAberto/PedidoAberto";
import PedidoAction from "./PedidoAction/PedidoAction";
import PedidoTotais from "./PedidoTotais";

const Cart = (props) => {
  return (
    <>
      <div
        className={"modal fade " + CSS.modal}
        id="cart"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div
          className={
            "modal-dialog modal-dialog-centered " +
            CSS.modal_dialog +
            " " +
            CSS2.modal_dialog
          }
        >
          <div
            className={
              "modal-content " + CSS.modal_content + " " + CSS2.modal_content
            }
          >
            <div className="modal-body p-0">
              <div className={CSS2.modal_body}>
                <div
                  className={
                    CSS2.body_header + " py-3 px-lg-3 px-2 border-bottom"
                  }
                >
                  <div className="row align-items-center mx-0">
                    <div className="col text-center fw-bold text-secondary">
                      Carrinho
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                </div>

                <div className={CSS2.body_scrolling}>
                  <div className="p-4">
                    <PedidoAberto />

                    <Cupom title={false} />

                    <PedidoTotais />
                  </div>
                </div>

                <div className={CSS2.body_action + " px-lg-4 p-3 border-top"}>
                  <PedidoAction />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
