const Status = ({ pedido }) => {
  return (
    <>
      <div className="mb-4">
        <h4 className="text-success opacity-5 fsc-1p2">Status do pedido</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <div className="fsc-1p2 fw-light fsc-m-1">
          {pedido.status === "COMPLETED" && "Concluído"}
          {pedido.status === "DELIVERING" && "Rota de Entrega"}
          {pedido.status === "WAITING_DELIVER" && pedido.address !== null &&  "Aguardando Entregador"}
          {pedido.status === "WAITING_DELIVER" && pedido.address === null &&  "Aguardando Retirada"}
          {(pedido.status === "PREPARING" || pedido.status === "PAID") && "Preparando"}
          {pedido.status === "ANALYZING" && "Em Análise"}
          {pedido.status === "PROCESSING" && "Aguardando Pagamento"}
          {pedido.status === "CANCELED" && "Cancelado"}
          </div>
        )}
      </div>
    </>
  );
};

export default Status;
