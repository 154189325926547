import { useState } from "react";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import CSS from "../../components/Layout/Modal/Modal.module.css";
import CSS2 from "./ModalProdutos.module.css";
import ProdutosInfo from "./ProdutosInfo/ProdutosInfo";
import ModalAction from "./ModalAction/ModalAction";

const ModalProdutos = forwardRef((props, ref) => {
  const [product, setProduct] = useState(dummy_product);
  const [listOptions, setListOptions] = useState([]);
  const [editMode, setEditMode] = useState({edit: false, index: null, timestamp: 0});
  const [defaultValue, setDefaultValue] = useState(null);

  const dismissBtn = useRef();
  const openBtn = useRef();

  const modalAction = useRef();

  useImperativeHandle(ref, () => ({
    addNew, edit
  }));

  function addNew(originalProduct) {
    setEditMode({edit: false, index: null, timestamp: Date.now()});
    setProduct(originalProduct);
    generateOptionsList(originalProduct);
    openModal();
  }

  function edit(productInCart, index){
    setDefaultValue({...productInCart});
    setEditMode({edit: true, index: index, timestamp: Date.now()});
    setProduct(productInCart);
    setListOptions(productInCart.choosed_ingredients);
    openModal();
  }

  function openModal(){
    modalAction.current.reset();
    openBtn.current.click();   
  }

  function generateOptionsList(product) {
    const newList = [];

    if (product.ingredients) {
      product.ingredients.forEach((e) => {
        newList.push([]);
      });
    }

    setListOptions(newList);
  }

  function onUpdateOptions(groupIndex, list) {
    let newList = [...listOptions];

    newList[groupIndex] = list;

    setListOptions(newList);
  }

  return (
    <>
      <div
        ref={openBtn}
        className={CSS.hidden}
        data-bs-toggle="modal"
        data-bs-target={"#product-information"}
      ></div>

      <div
        className={"modal fade " + CSS.modal}
        id="product-information"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div
          className={
            "modal-dialog modal-dialog-centered " +
            (product.photo !== null && product.photo !== ""
              ? "modal-xl "
              : "modal-lg ") +
            CSS.modal_dialog +
            " " +
            CSS2.modal_dialog
          }
        >
          <div
            className={
              "modal-content " + CSS.modal_content + " " + CSS2.modal_content
            }
          >
            <div className="modal-header d-lg-none">
              <h5 className="modal-title fsc-1 text-secondary fw-bold" id="staticBackdropLabel">
                { product.name }
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body p-0">
              <div className="row align-items-stretch mx-0">
                {product.photo !== null && product.photo !== "" && (
                  <div className="col-lg-6 col-12 p-5 d-lg-flex d-none ">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        className={CSS2.photo + " rounded"}
                        src={
                          process.env.REACT_APP_IMG_URL + "/" + product.photo
                        }
                        alt=""
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg col-12 px-0">
                  <div className={CSS2.modal_body}>
                    <div className="row d-none d-lg-flex align-items-center mx-0 py-4 px-3">
                    <div className="col text-center fw-bold text-secondary">
                        {product.name}
                    </div>
                    <div className="col-auto">
                        <button
                        ref={dismissBtn}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    </div>
                    </div>

                    <div className={CSS2.body_scrolling}>
                      {product.photo !== null && product.photo !== "" && (
                        <div className={"d-lg-none " + CSS2.photo_mobile}>
                          <img
                            className={CSS2.photo}
                            src={
                              process.env.REACT_APP_IMG_URL +
                              "/" +
                              product.photo
                            }
                            alt=""
                          />
                        </div>
                      )}

                      <div className="p-4">
                        <ProdutosInfo
                          product={product}
                          defaultValue={defaultValue}
                          edit={editMode}
                          onUpdate={onUpdateOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
                <div className='m-0 w-100'>
                      <ModalAction
                        product={product}
                        edit={editMode}
                        listOptions={listOptions}
                        defaultValue={defaultValue}
                        ref={modalAction}
                      />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ModalProdutos;

const dummy_product = {
  id: 0,
  name: null,
  photo: null,
};
