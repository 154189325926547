import { createContext } from "react";
import ModalProdutos from "./ModalProdutos";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const ModalProdutosContext = createContext({
  addNew: (product) => {},
  edit: (product, index) => {},
});
export default ModalProdutosContext;

export const ModalProdutosProvider = forwardRef(({ children }, ref) => {
  const modal = useRef();

  useImperativeHandle(ref, () => ({
    edit,
  }));

  function addNew(product) {
    modal.current.addNew(product);
  }

  function edit(product, index) {
    modal.current.edit(product, index);
  }

  const context = {
    addNew: addNew,
    edit: edit,
  };

  return (
    <ModalProdutosContext.Provider value={context}>
      {children}

      <ModalProdutos ref={modal} />
    </ModalProdutosContext.Provider>
  );
});
