import { useContext } from "react";
import { NavServiceProvider } from "./Categorias/Nav/NavService";
import ListService from "../../components/shared/Lists/ListService";
import NavHash from "./Categorias/Nav/NavHash";
import NavAndSearch from "./NavAndSearch/NavAndSearch";
import CategoriaLista from "./Categorias/Lista/CategoriaLista";
import FontAwesome from "../../components/Layout/FontAwesome";
import Cart from "../Cart/Cart";
import CartButtonMobile from "../../components/Layout/Nav/CartButton/CartButtonMobile";

const Cardapio = (props) => {
  const listService = useContext(ListService);

  return (
    <>
      <NavServiceProvider>
        <NavAndSearch />

        <div className="container px-0 px-lg-4">
          {listService.list.map((category, index) => {
            if(category.list.length === 0) return null;

            return (
              <NavHash key={index} index={index}>
                <CategoriaLista category={category} />
              </NavHash>
            );
          })}

          {listService.list.length === 0 && (
            <div className='d-none d-lg-block text-center fsc-1p5 mt-5 pt-5 opacity-6'>
              Parece que não há itens. <FontAwesome icon="face-sad-cry" />
            </div>
          )}
        </div>
      </NavServiceProvider>
      
      <Cart />
      
      <CartButtonMobile />
    </>
  );
};

export default Cardapio;
