import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";
import { useState } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";
import Entrega from "./Entrega";
import Pagamento from "./Pagamento";
import { useContext } from "react";
import AuthService from "../Auth/Auth";
import Status from "./Status";
import Data from "./Data";
import Items from "./Items";
import Valores from "./Valores";
import CSS from "./Pedido.module.css";

const Pedido = (props) => {
  const auth = useContext(AuthService);
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [pedido, setPedido] = useState({ id: null });

  useEffect(() => {
    if (auth.init) {
      if (auth.user.jwt) {
        getOrder();
      } else {
        onNotFound();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.init]);

  function getOrder() {
    const body = { jwt: auth.user.jwt };

    HttpRequest.post(
      "/pedidos/" + orderId,
      JSON.stringify(body),
      onSuccessGet,
      onNotFound
    );
  }

  function onSuccessGet(response) {
    setPedido(response.order);
  }

  function onNotFound() {
    navigate("/pedidos");
  }

  return (
    <>
      <div className={"container pt-5 " + CSS.pedido} style={{ maxWidth: 600 }}>
        <div className="text-center d-none d-print-block">
          <img
            alt="logo"
            src="img/logo/logo-full-small.png"
            style={{ width: 100 }}
            className="mb-5"
          />
        </div>

        <h2 className="text-success mb-5 fsc-2">
          <span className="opacity-7 me-2">
            <FontAwesome icon="receipt" />
          </span>{" "}
          Pedido {pedido.id}
        </h2>

        <Items pedido={pedido} />

        <hr className="my-4 opacity-1" />

        <Valores pedido={pedido} />

        <hr className="my-4 opacity-1" />

        <Status pedido={pedido} />

        <Data pedido={pedido} />

        <Entrega pedido={pedido} />

        <Pagamento pedido={pedido} />

        <div className="d-print-none mb-5">
          <hr className="my-5" />

          <div className="text-center fw-light fsc-m-n8">
            <div>Precisando de ajuda com seu pedido?</div>
            <div>Entre em contato conosco diretamente pelo Whatsapp!</div>

            <a
              href={
                "https://api.whatsapp.com/send?phone=5514998119599&text=Olá, preciso de ajuda com meu pedido pelo site.%0a*Pedido nº: " +
                pedido.id +
                '*'
              }
              target="_blank"
              rel="noreferrer"
            >
              <div className="btn btn-outline-success fsc-1p2 py-lg-3 py-2 px-4 mt-3 fw-bold">
                <FontAwesome type="brands" icon="whatsapp" />{" "}
                <span className="ms-2">(14) 99811-9599</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pedido;
