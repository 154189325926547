import { useRef, useState } from "react";
import HttpRequest from "../../misc/classes/HttpRequest/HttpRequest";

const InformarEmail = ({ onSuccess }) => {
  const emailRef = useRef();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setErrors(null);

    HttpRequest.post(
      "/recuperar-senha",
      JSON.stringify({ email: emailRef.current.value }),
      onSuccessSubmit,
      onError
    );
  };

  const onSuccessSubmit = (data) => {
    onSuccess(data.id);
    setLoading(false);
  };

  const onError = (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }

    setErrors(error);

    setLoading(false);
  };

  return (
    <>
      <p>
        Informe seu e-mail abaixo para enviarmos um código de recuperação de
        senha.
      </p>

      <form className="mt-5" onSubmit={onSubmit}>
        <div className="form-group">
          <label>E-mail</label>
          <input
            className="form-control"
            required
            type="email"
            name="email"
            ref={emailRef}
          />
        </div>

        {errors && (
          <div className="alert alert-danger fsc-n9 mt-4">{errors.message}</div>
        )}

        {loading && (
          <div className="btn btn-success w-100 py-2 mt-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}

        {!loading && (
          <button className="w-100 btn btn-success py-3 fw-bold mt-4">
            Enviar
          </button>
        )}
      </form>
    </>
  );
};

export default InformarEmail;
